import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';

function ModalConsultaCargaHoraria(props) {
    const { estrutura } = props;
    const { alunos } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "alunos",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Grupo',
                    accessor: 'grupo_geral',
                },
                {
                    Header: 'Dia',
                    accessor: 'atividade_date',
                },
                {
                    Header: 'CHR',
                    accessor: 'carga_horaria',
                },
                {
                    Header: 'Tipo Atividade',
                    accessor: 'nome_atividade',
                },
                {
                    Header: 'Atividade',
                    accessor: 'nome_aula',
                },
                {
                    Header: 'Professor',
                    accessor: 'professor',
                }
            ],
        }],
        []
    )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"

            {...props}
        >
            <Tabela
                columns={columns}
                data={alunos}
                tabelaClassName="tabelaConsulta"
            />
        </ModalConsulta>
    );
}

export default ModalConsultaCargaHoraria;
