import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Col, Label, FormFeedback, FormGroup } from "reactstrap"
import { axiosApi } from '../axiosInstances'
import { selectStyle } from '../funcoes'
import AsyncSelect from 'react-select/async'
import pick from 'lodash/pick';
/********************************************************************************************************************************************/
//Componente responsavel por configurar um select do react-select de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/******************************************************************************************************************************************/

const AsyncSelectForm = (props) => {
    const [manipuladoPeloUsuario, setManipuladoPeloUsuario] = useState(false);
    const variaveis = useRef({ vazio: false, placeholder: props.placeholder.replace('%n%', props.minCharPlaceholder)});
    
    const onChange = (valor) => {
        if (props.isMulti) {
            valor = valor ? valor : [];
            let valores = valor.map(elemento => (elemento.value));
            props.onChange(props.name, valores, props.indice);
        } else {
            props.onChange(props.name, valor.value, props.indice);
        }
    };

    const loadOptions = async (query, callback) => {
        try {
            if (query && query.length >= props.minCharPlaceholder) {
                const params = {
                    selectQuery: query,
                    selectName: props.name,
                    requisicaoURL: props.requisicaoURL,
                    valoresAtuais: pick(props.valoresAtuais, props.params)
                }
                const response = await axiosApi.get(props.urlPaginacao, { params })
                variaveis.current.vazio = response.data.dados.length <= 0 ? true : false;
                callback(response.data.dados);
            } else {
                variaveis.current.vazio = false;
            }
        } catch (error) {
            // console.log(error);
            variaveis.current.vazio = false;
        }
    }

    const noOptions = () => (props.valorPadrao || !variaveis.current.vazio ? variaveis.current.placeholder : " Não há opções para essa busca");

    const isValid = () => ((manipuladoPeloUsuario || props.submit) && props.invalido);

    const changeClass = () => (isValid() ? "is-invalid input-invalid" : "");

    const loadingMessage = () => ("Carregando...");

    return (
        <Col {...props.style.col} className={"form-col " + props.colunaClass}>
            <FormGroup className={(props.hidden ? "hidden" : "")}>
                <Label className={props.style.labelClass + " label"}>
                    {props.label + ":"}
                </Label>
                {/* <FormGroup className="form-group-feedback-input"> */}
                    <AsyncSelect
                        cacheOptions={props.cacheOptions}
                        defaultOptions={props.options}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        noOptionsMessage={noOptions}
                        loadingMessage={loadingMessage}
                        placeholder={variaveis.current.placeholder}
                        isDisabled={props.readOnly}
                        defaultValue={props.valorPadrao}
                        onBlur={() => setManipuladoPeloUsuario(true)}
                        styles={selectStyle}
                        className={"select select-form " + props.style.elementoClass + changeClass()}
                    />
                    <FormFeedback style={{ display: isValid() ? "block" : "none" }}>
                        Selecione uma opção valida !

                    </FormFeedback>
                {/* </FormGroup> */}
            </FormGroup>
        </Col>
    );
}

export default React.memo(AsyncSelectForm);

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)

}

AsyncSelectForm.defaultProps = {
    hidden: false,
    cacheOptions: true,
    placeholder: "Digite %n% caracteres para iniciar a busca",
    minCharPlaceholder: 3,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "select select-form",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};

AsyncSelectForm.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    valorInicial: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}

