import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { IconInput } from '../index'
import { Link } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const MudarSenhaForm = (props) => {
    if (!props.tokenRecuperacaoValido && !props.loader) {
        props.validarRecuperacaoToken(props.match.params.token, props.history)
    }

    const [verSenha, setVerSenha] = useState(false);
    const [verSenha2, setVerSenha2] = useState(false);

    const onClick = () => {
        setVerSenha(!verSenha)
    }

    const onClick2 = () => {
        setVerSenha2(!verSenha2)
    }

    return (

        <div className="div-form-login" >

            <div className="div-wrapper-centralizado">
                <p className='texto-explicativo-superior'>
                    Preencha os campos para que possamos alterar sua senha !
                </p>
            </div>

            <IconInput tipo={"usuario"}
                tipoPattern={true}
                placeholder="Digite seu E-mail"
                onChange={props.onChange}
                invalido={props.invalido[0]}
                defaultValue={props.formulario.usuario}
                submit={props.submit}
            />

            {
                verSenha ? <AiOutlineEye onClick={onClick} className='ver-senha-recuperar' /> : <AiOutlineEyeInvisible onClick={onClick} className='ver-senha-recuperar' />
            }
            <IconInput
                tipo={`${verSenha ? 'senhaVisivel' : 'senha'}`}
                placeholder="Senha"
                onChange={props.onChange}
                invalido={props.invalido[1]}
                submit={props.submit}
                name="senha"
            />

            {
                verSenha2 ? <AiOutlineEye onClick={onClick2} className='ver-senha-recuperar2' /> : <AiOutlineEyeInvisible onClick={onClick2} className='ver-senha-recuperar2' />
            }
            <IconInput
                tipo={`${verSenha2 ? 'senhaVisivelConfirmacao' : 'senha_confirmacao'}`}
                placeholder="Confime sua senha"
                onChange={props.onChange}
                invalido={props.invalido[3]}
                submit={props.submit}
                name="senha_confirmation"
            />

            <Button className="btnHeader"
                onClick={() => props.submitOnClick(props.match.params.token)}>
                Mudar
            </Button>

            <Link to='/login' className='color-verde'>Voltar ?</Link>
        </div>
    )
}

export default MudarSenhaForm
