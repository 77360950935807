import * as tiposActions from './tiposActions'
import { axiosApi } from '../../Basicos/axiosInstances'

export const alterarTamanhoJanela = (window) => {
    return {
        type: tiposActions.ATUALIZAR_TAMANHO_TELA,
        modoMobile: window.innerWidth <= 575,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
    }
}

export const inicializarCore = (funcionalidades) => {
    return {
        type: tiposActions.INICIALIZAR_CORE,
        funcionalidades,
    }
}

export const ativarSidebar = (evento) => {
    return (dispatch, getState) => {
        const { core } = getState();
        if (!core.modoMobile) {
            dispatch({ sidebarAtivo: evento.type === "mouseleave" ? false : true, type: tiposActions.ALTERAR_ESTADO_SIDEBAR });
        } else if (evento.type === "click") {
            dispatch({ sidebarAtivo: !core.sidebarAtivo, type: tiposActions.ALTERAR_ESTADO_SIDEBAR });
        }
    }
}

export const mudarSolucao = (solucao_id) => {
    return {
        type: tiposActions.ALTERAR_SOLUCAO,
        solucao_id,
    }
}

export const gerarXlsx = async (params, url) => {
    try {
        params.formatoArquivo = "xlsx";
        const response = await axiosApi.post(url, params)
        window.open(response.data.url, '_blank');
        return true;
    } catch (error) {
        throw new Error("Não achado");
    }
}

export const gerarPDF = async (params, url) => {
    try {
        params.formatoArquivo = "pdf";
        const response = await axiosApi.post(url, params)
        window.open(response.data.url, '_blank');
        return true;
    } catch (error) {
        throw new Error("Não achado");
    }
}