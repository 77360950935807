import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';

function ModalAulasCanceladas(props) {
    const { estrutura } = props;
    const { aulas } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "Aulas",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Tema',
                    accessor: 'tema_nome',
                },
                {
                    Header: 'Data Aula',
                    accessor: 'data_aula',
                    type: 'data'
                },
                {
                    Header: 'Hora inicio',
                    accessor: 'inicio',
                },
                {
                    Header: 'Carga Horária',
                    accessor: 'carga_horaria_min',
                },
                {
                    Header: 'Tipo Atividade',
                    accessor: 'tipo_atividade',
                },
                {
                    Header: 'Titulo Aula',
                    accessor: 'titulo',
                },
                {
                    Header: 'Professor',
                    accessor: 'professores',
                },
                {
                    Header: 'Grupo',
                    accessor: 'grupo',
                },
                {
                    Header: 'Data e Hora do Cancelamento',
                    accessor: 'data_hora_cancelamento',
                    type: 'dataAndHour'
                },
                {
                    Header: 'Motivo Cancelamento',
                    accessor: 'motivo_cancelamento',
                }
            ],
        }],
        []
    )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            
            {...props}
        >
            <Tabela
                columns={columns}
                data={aulas}
                tabelaClassName="tabelaConsulta"
            />
        </ModalConsulta>
    );
}

export default ModalAulasCanceladas;
