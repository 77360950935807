import React from 'react'
import { Label, Col, Row } from 'reactstrap';

const Bloco = (props) => {
    return (
        <Col {...props.elemento.col} className="form-col">
            <Row>
                <Label sm={12} className={"label-conteudoInferior text-dark"}>
                    <b> {props.elemento.titulo} </b>
                </Label>
                {props.children}
            </Row>
        </Col>
    )
}
Bloco.defaultProps = {
    elemento: {
        Col: {
            xs: 12,
            sm: 12,
            md: null,
            lg: null,
            xl: null
        }
    }
};

export default Bloco
