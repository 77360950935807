import React from 'react'
const CampoExportar = ({ xlsx, dados, definirOnclick, style, className }) => {
    return xlsx.ativo && (
        <div style={style} className={className}>
            <svg 
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="1.6em"
                height="1.6em" 
                viewBox="0 0 351.000000 301.000000"
                preserveAspectRatio="xMidYMid meet" 
                onClick={(e) => definirOnclick({ onClick: xlsx.onClick, params: xlsx.params, dados, e })}
            >
                <g 
                    transform="translate(0.000000,301.000000) scale(0.100000,-0.100000)"
                    fill="var(--cor-filtro-consulta)" 
                    stroke="none"
                >
                    <path d="M890 2851 c-448 -87 -832 -161 -852 -165 l-38 -7 0 -1174 c0 -646 4
                    -1175 8 -1175 5 0 394 -74 866 -165 471 -91 859 -165 861 -165 3 0 5 677 5
                    1505 0 1427 -1 1505 -17 1504 -10 0 -385 -72 -833 -158z m-130 -955 c49 -101
                    94 -202 101 -225 7 -22 15 -41 19 -41 4 0 13 19 19 43 7 23 57 124 110 224
                    l96 183 138 0 138 0 -12 -22 c-7 -13 -84 -141 -171 -286 l-160 -262 118 -193
                    c65 -105 146 -238 181 -294 l63 -103 -149 0 -149 0 -107 202 c-58 111 -109
                    215 -112 230 -8 39 -19 35 -36 -14 -8 -24 -60 -126 -115 -228 l-100 -185 -146
                    -3 c-80 -1 -146 0 -146 4 0 4 81 134 180 289 99 155 180 286 180 290 0 3 -73
                    131 -161 283 -89 152 -163 280 -166 285 -2 4 64 7 147 7 l151 0 89 -184z"/>
                    <path d="M1840 2485 l0 -175 175 0 175 0 0 -110 0 -110 -175 0 -175 0 0 -120
                    0 -120 175 0 175 0 0 -110 0 -110 -175 0 -175 0 0 -120 0 -120 175 0 175 0 0
                    -115 0 -115 -175 0 -175 0 0 -120 0 -120 175 0 175 0 0 -110 0 -110 -175 0
                    -175 0 0 -175 0 -175 552 0 552 0 28 27 28 27 0 233 0 233 -193 145 -194 145
                    -152 0 -152 0 1 117 c1 88 -2 128 -14 155 -20 47 -20 89 0 136 12 28 16 66 14
                    160 l-1 122 155 0 156 0 190 142 190 143 0 238 0 238 -25 24 -24 25 -556 0
                    -555 0 0 -175z m920 -285 l0 -110 -225 0 -225 0 0 110 0 110 225 0 225 0 0
                    -110z m0 -1390 l0 -110 -225 0 -225 0 0 110 0 110 225 0 225 0 0 -110z"/>
                    <path d="M2859 1947 c-316 -237 -489 -373 -497 -391 -17 -37 -15 -83 4 -114 9
                    -15 231 -188 495 -385 515 -387 518 -388 587 -353 17 9 35 24 40 34 5 9 12
                    351 15 762 l5 744 -33 33 c-28 28 -40 33 -82 33 -50 0 -50 0 -534 -363z"/>
                </g>
            </svg>
        </div>
    )
}

CampoExportar.defaultProps = {
    definirOnclick: () => (undefined),
    xlsx: {
        ativo: false,
        onClick: 'gerarXlsx'
    },
    style: {
        position: "absolute",
        right: "20px",
        margin: "-2px",
        zIndex: 1,
        cursor: 'pointer',
    },
    dados:{}
};

export default CampoExportar
