import React from 'react'
import CampoGenerico from '../Campos/campoGenerico.jsx';
import { Row, Col } from 'reactstrap';

const ModalFooter = ({ estrutura }) => {
    const render = estrutura && estrutura.modal && estrutura.modal.footer ? true : null;
    return render && (
        <Row noGutters className={'w-100 modal-footer-novo'}>
            <Col sm={12} className={'modal-footer-titulo'}>
                {estrutura.modal.footer.titulo}
            </Col>
            {estrutura.modal.footer.campos.map((elemento, indice) => {
                return <CampoGenerico {...elemento} dados={estrutura.modal.footer.dados} key={indice} />
            })}
        </Row>
    );
}

export default ModalFooter;
