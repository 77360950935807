import React, { Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { primeiraImagemDeUmaNoticia } from '../../../services/noticias';

function CardDashboards(props) {
    const {noticias} = props;

    return (
        <Fragment>
            < Card>
            </Card>
            <h3 className="font-weight-bold azul-principal mb-3">Mais Lidas</h3>
            <Card>
                <CardBody>
                    {
                        noticias.map(noticia => {
                            return(
                                <Link to={`/eventos-e-noticias/${noticia.id}`} key={noticia.id}>
                                    <div className="d-flex mb-4 align-middle">
                                        <img 
                                            src={primeiraImagemDeUmaNoticia(noticia)}
                                            className="shadow-sm"
                                            alt=""
                                            style={{ height: "90px", width: "90px", borderRadius: "16px", objectFit: "cover" }}
                                        />
                                        <span className="align-middle font-weight-bold my-auto ml-2 azul-principal">
                                            {(noticia.titulo).substring(0, 50)}
                                        </span>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </CardBody>
            </Card>
        </Fragment>
    );
}

export default CardDashboards;