import React from 'react'
import { Col } from 'reactstrap';


const ParteInferior = ({selectChange,textClass}) => {
  return (
    <Col sm = {12} md ={12} className = {textClass}>
        <label> 
          
        </label>
    </Col>

  )
}

export default ParteInferior

