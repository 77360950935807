import React, { Fragment, useState } from 'react'
import { Row, Col } from 'reactstrap';
import { InputForm, SelectForm } from '../../Basicos';
import { axiosApi } from '../../Basicos/axiosInstances';

const HeaderCalendario = ({ camposSemanaHeader }) => {
    const style = {
        background: '#cacada',
        color: 'black',
        fontSize: '1rem',
        textAlign: 'center',
        border: '1px groove'
    }

    return (
        <Row>
            <Col className='align-content-middle' style={style} sm={1} md={1} lg={1} xl={1}>
            </Col>
            {camposSemanaHeader.map((campo, index) => (
                <Col className='align-content-middle' style={style} key={index}>
                    {campo}
                </Col >
            ))}
        </Row>
    )
}

const BodyCalendario = ({ dadosCalendario, horarios, onClick }) => {
    const semanaUm = dadosCalendario.slice(0, 7);
    const semanaDois = dadosCalendario.slice(7, 14);
    const semanaTres = dadosCalendario.slice(14, 21);
    const semanaQuatro = dadosCalendario.slice(21, 28);

    const semanas = [semanaUm, semanaDois, semanaTres, semanaQuatro];
    const cores = ['white', '#cfff99', '#ffca8a', '#ffa38a'];
    const disponibilidade = ['', 'Prefiro', 'É Possível', 'Não é Possível'];

    return (
        <Fragment>
            {semanas.map((semana, index) => (
                <Row style={{ height: '100px' }}>
                    <Col className="align-content-middle"
                        sm={1} md={1} lg={1} xl={1}
                        style={{
                            background: '#cacada',
                            fontSize: '.75rem',
                            textAlign: 'center',
                            border: '1px groove'
                        }}
                    >
                        {horarios[semana[index].horario_id]}
                    </Col>
                    {semana.map((dia, index) => (
                        <Col key={index}
                            onClick={() => onClick(dia)}
                            className="align-content-middle"
                            style={{
                                background: cores[dia.tipo_de_disponibilidade_id],
                                border: '1px groove',
                                textAlign: 'center',
                                color: 'black',
                                cursor: 'pointer'
                            }}>
                            {disponibilidade[dia.tipo_de_disponibilidade_id]}
                        </Col>
                    ))}
                </Row>
            ))}
        </Fragment>
    )
}


const CardCalendarioDisponibilidade = ({ estruturaPadrao }) => {
    const [dadosCalendario, setDadosCalendario] = useState(estruturaPadrao.slice())
    const [diaSelecionado, setDiaSelecionado] = useState();
    const selectOptions = [
        {
            label: 'Prefiro',
            value: 1
        }, {
            label: 'É Possível',
            value: 2
        }, {
            label: 'Não é Possível',
            value: 3
        }
    ];

    const Horarios = ['', 'AB Manhã', 'CD Manhã', '', 'AB Tarde', 'CD Tarde'];
    const DiasSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const colAlignStyle = { className: 'align-content-middle', sm: 12, md: 12, lg: 12, xl: 12 };

    const onChangeDisponibilidade = (name, value) => {
        const indice = dadosCalendario.findIndex((dado) => dado.id === diaSelecionado.id);
        return (
            setDiaSelecionado({
                ...diaSelecionado,
                [name]: value
            }),
            dadosCalendario[indice].tipo_de_disponibilidade_id = value,
            onSubmit(),
            setDadosCalendario(dadosCalendario.slice())
        )

    }

    const onClick = (dia) => {
        setDiaSelecionado(dia)
    }

    const onSubmit = async () => {
        try {
            const response = await axiosApi.put(`/professores/disponibilidades/${diaSelecionado.id}`, diaSelecionado);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Col sm={3} md={3} lg={3} xl={3}>
                <Row noGutters className='shadow-sm' style={{ border: '1px solid', borderRadius: '20px', borderColor: 'var(--cor-principal)', height: '100%' }}>
                    <Col className='align-content-middle py-3' sm={12} md={12} lg={12} xl={12}>
                        <h3>Disponilidade</h3>
                    </Col>
                    <Col {...colAlignStyle}>
                        <InputForm
                            label="Dia"
                            type="text"
                            name="dia_da_semana"
                            value={diaSelecionado ? DiasSemana[diaSelecionado.dia_da_semana] : 'Selecione Dia'}
                            disabled />
                    </Col>
                    <Col {...colAlignStyle}>
                        <InputForm
                            label="Horário"
                            type="text"
                            name="horario_id"
                            value={diaSelecionado ? Horarios[diaSelecionado.horario_id] : 'Selecione Dia'}
                            disabled />
                    </Col>
                    <Col {...colAlignStyle}>
                        <SelectForm
                            label="Disponibilidade"
                            name="tipo_de_disponibilidade_id"
                            onChange={onChangeDisponibilidade}
                            options={selectOptions}
                            readOnly={diaSelecionado === undefined}
                            placeholder={'Selecione Dia'}
                            value={diaSelecionado ? selectOptions.find(option => option.value === diaSelecionado.tipo_de_disponibilidade_id) : 'Selecione'}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <HeaderCalendario camposSemanaHeader={DiasSemana} />
                {
                    estruturaPadrao.length > 0 ?
                        <BodyCalendario dadosCalendario={dadosCalendario} horarios={Horarios} onClick={onClick} />
                        :
                        <Row noGutters style={{ height: '100%' }}>
                            <Col className="align-content-middle">
                                <h3 style={{ color: 'var(--cor-principal)' }}>
                                    Não há dados.
                                </h3>
                            </Col>
                        </Row>
                }
            </Col>
        </Fragment >
    )
}


export default CardCalendarioDisponibilidade;