import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Label, FormFeedback, FormGroup } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    convertToRaw,
    convertFromHTML,
    ContentState
} from "draft-js";
import draftToHtml from "draftjs-to-html";
// import "./editor.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
        xhr.open("POST", "https://api.imgur.com/3/image");
        xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
        const data = new FormData(); // eslint-disable-line no-undef
        data.append("image", file);
        xhr.send(data);
        xhr.addEventListener("load", () => {
            const response = JSON.parse(xhr.responseText);
            resolve(response);
        });
        xhr.addEventListener("error", () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    });
}

function htmlToState(html) {
    if (html) {
        const blocksFromHTML = convertFromHTML(html);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(state);
    } else {
        return null;
    }
}

const RichTextForm = ({
    valorPadrao,
    onChange,
    name,
    invalido,
    submit,
    style,
    hidden,
    colunaClass,
    label }) => {

    const [manipuladoPeloUsuario, setManipuladoPeloUsuario] = useState(false);
    const [editorState, setEditorState] = useState(htmlToState(valorPadrao));

    const validateHtml = (stateHtml) => {
        return stateHtml !== '<p></p>' ? stateHtml : '';
    }
    const saveHtml = (state) => {
        const stateHtml = state
            ? validateHtml(draftToHtml(convertToRaw(state.getCurrentContent())))
            : '';

        onChange(name, stateHtml);
    }

    const onChangeEditor = (state) => {
        setEditorState(state);
        saveHtml(state);
    }
    const changeClass = () => (isValid() ? "is-invalid input-invalid" : " ");

    const isValid = () => ((manipuladoPeloUsuario || submit) && invalido);

    return (
        <Col {...style.col} className={"form-col " + colunaClass}>
            <FormGroup className={(hidden ? "hidden" : "")}>
                <Label className={style.labelClass + " label"}>
                    {label + ":"}
                </Label>
                {/* <FormGroup className="form-group-feedback-input"> */}
                <Editor
                    
                    wrapperClassName = "classe de wrapper"
                    editorClassName = "draft-editor"
                    toolbarClassName = "draft-tools"
                    wrapperClassName="draft-body"
                    
                    editorState={editorState } //AQUI
                    onEditorStateChange={onChangeEditor} //AQUI
                    onBlur={() => setManipuladoPeloUsuario(true)}
                    toolbar={{
                        image: {
                            uploadCallback: uploadImageCallBack,
                            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                            alt: { present: true, mandatory: false }
                        }
                    }}
                />
                <FormFeedback style={{ display: isValid() ? "block" : "none" }}>
                    Digite algo valido !
                </FormFeedback>
                {/* </FormGroup> */}
            </FormGroup>
        </Col>
    );
}




const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

RichTextForm.defaultProps = {
    hidden: false,
    placeholder: "",
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }
    }
};

RichTextForm.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    valorInicial: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    maxOptions: PropTypes.number,
    style: PropTypes.shape(styleShape),
}

export default React.memo(RichTextForm);
