import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Label, FormGroup, FormFeedback } from "reactstrap";
import PropTypes from 'prop-types';
import uploadIcon from "../../images/ic_imagem.png";
import excelIcon from "../../images/ic_excel.png"
import MenuTable from '../../Menu/menuTable';

import { TiDocument } from 'react-icons/ti';

//comparar tamanho do array files , ver quantos falta e dar um slice no array accept, caso esteja lotado manda o aviso
function FileExportForm({
    accept,
    placeholder,
    style,
    label,
    preview,
    valorAtual,
    onChange,
    name,
    file_name,
    limiteDeArquivos,
    minSize,
    maxSize,
    excel
}) {

    const files = valorAtual;

    const [erroFileName, setErroFileName] = useState(false);

    const onDrop = (acceptedFiles) => {

        let quantidadeRestante = limiteDeArquivos - files.length;
        //se file_name Existir, valida nome do arquivo
        if (excel && file_name) {
            //fluxo com validação de nome
            const partFileName = file_name.split('&');
            const partAccepteFileName = acceptedFiles[0].name.split('&')
            if (partFileName[0] === partAccepteFileName[0]) {
                onChange(name, [...files, ...acceptedFiles.slice(0, quantidadeRestante)]);
                setErroFileName(false);
            } else {
                setErroFileName(true);
            }
        } else {
            //fluxo normal
            onChange(name, [...files, ...acceptedFiles.slice(0, quantidadeRestante)]);
        }
    }

    const defineImageURL = (file) => file instanceof File ? URL.createObjectURL(file) : file.url;

    const isImage = (file) => file instanceof File ? file.type.includes('image') : file.tipo_mime.includes('image');

    const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
        accept: accept,
        maxSize: maxSize,
        minSize: minSize,
        onDrop
    });

    const aceitos = files.map((file, indice) => {

        const removerOnClick = () => {
            const auxFiles = files.slice();
            auxFiles.splice(indice, 1);
            onChange(name, auxFiles);
        }

        return (
            <div key={indice} style={{
                display: "flex",
                flexDirection: "row",
                margin: "8px",
            }}>

                {(preview && isImage(file)) ? (
                    <img src={defineImageURL(file)} className="img-file" />
                ) : (
                        <TiDocument className="menuIcons img-file" />
                    )}

                <span style={{ flex: 1, margin: "0 10px 0 10px", lineHeight: "60px" }}>
                    {file instanceof File ? file.name : file.nome}
                </span>
                <MenuTable
                    alinharDireita
                    iconRemover
                    removerOnClick={removerOnClick}
                />
            </div>
        )
    });

    const rejeitados = rejectedFiles.map((file, indice) => (
        <FormFeedback style={{ display: "block" }} key={indice}>
            {`Arquivo ${file instanceof File ? file.name : file.nome} é inválido verifique o tamanho ou nome!`}
        </FormFeedback>
    ));

    return (
        <Col {...style.col} className="form-col">
            <FormGroup className="form-group">
                {label && (
                    <Label className={style.labelClass + " label"} >
                        {label + ":"}
                    </Label>
                )}

                {aceitos}
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {
                        excel ?
                            <img src={excelIcon} width="100" height="100" /> :
                            <img src={uploadIcon} width="150" height="150" />
                    }
                    <p className="m-0" >Arraste e solte alguns arquivos aqui ou clique para selecionar os arquivos</p>
                    {placeholder && <em> {placeholder} </em>}
                </div>
                {rejeitados}
                {erroFileName &&
                    <FormFeedback style={{ display: "block" }} >
                        {`Arquivo é inválido verifique o nome!`}
                    </FormFeedback>
                }
            </FormGroup>
        </Col>

    );
}

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

FileExportForm.defaultProps = {
    mask: undefined,
    preview: true,
    valorAtual: [],
    limiteDeArquivos: 1000,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};

FileExportForm.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}

export default FileExportForm;