import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { Link } from 'react-router-dom';

const CarouselBase = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = props.items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
                style={{
                    height: '454px',
                    borderRadius: '16px',
                    width: "100%"
                }}
            >
                <Link
                    to={`/eventos-e-noticias/${item.id}`}
                    key={item.id}>
                    <img
                        src={item.src}
                        alt={item.altText}
                        style={{
                            width: "100%",
                            height: "454px",
                            borderRadius: "16px",
                            objectFit: "cover",
                            display: "block"
                        }} />
                </Link>
                <div className='TextCont'>
                    <CarouselCaption
                        captionText={(item.caption).substring(0, 100)}
                        captionHeader={(item.altText).substring(0, 100)}
                        className="carousel-caption"
                    />
                </div>
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators items={props.items} activeIndex={activeIndex} onClickHandler={goToIndex}>Tese</CarouselIndicators>
            {slides}
            {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
        </Carousel>
    );
}

export default CarouselBase;