//Alert
export const ATIVAR_ALERT = 'ATIVAR_ALERT';
export const ATIVAR_MODAL_CONFIRM = 'ATIVAR_MODAL_CONFIRM';
export const DESATIVAR_ALERT = 'DESATIVAR_ALERT';
export const DESATIVAR_MODAL_CONFIRM = 'DESATIVAR_MODAL_CONFIRM';

//Empresa
export const ATUALIZAR_EMPRESA = 'ATUALIZAR_EMPRESA';
//Auth
export const INICIO_AUTH = 'INICIO_AUTH';
export const SUCESSO_AUTH = 'SUCESSO_AUTH';
export const FALHOU_AUTH = 'FALHOU_AUTH';
export const LOGOUT_AUTH = 'LOGOUT_AUTH';
export const REDIRECIONAR_AUTH = 'REDIRECIONAR_AUTH';
export const ESTADO_RECUPERACAO_TOKEN = 'ESTADO_RECUPERACAO_TOKEN';
export const CURSO = 'CURSO';
export const PERIODO_LETIVO = 'PERIODO_LETIVO';

//Core
export const ATUALIZAR_TAMANHO_TELA = 'ATUALIZAR_TAMANHO_TELA';
export const INICIALIZAR_CORE = 'INICIALIZAR_CORE';
export const ALTERAR_SOLUCAO = 'ALTERAR_SOLUCAO';
export const ALTERAR_ESTADO_SIDEBAR = 'ALTERAR_ESTADO_SIDEBAR';

//SubFuncionalidades
export const ATUALIZAR_SUBFUNCIONALIDADE = 'ATUALIZAR_SUBFUNCIONALIDADE';
export const ATUALIZAR_MODAL_SUBFUNCIONALIDADE = 'ATUALIZAR_MODAL_SUBFUNCIONALIDADE';
export const TOGGLE_MODAL_SUBFUNCIONALIDADE= 'TOGGLE_MODAL_SUBFUNCIONALIDADE';
export const INCIALIZAR_SUBFUNCIONALIDADE = 'INCIALIZAR_SUBFUNCIONALIDADE';
export const TOGGLE_LOADER_SUBFUNCIONALIDADE = 'TOGGLE_LOADER_SUBFUNCIONALIDADE';
