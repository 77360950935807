import axios from 'axios'
import axiosRetry from 'axios-retry';

if (window.location.protocol !== 'https:'
    && process.env.NODE_ENV === 'production'
    && window.location.hostname !== 'localhost'
    && window.location.hostname !== '127.0.0.1'
) {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

export const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
    timeout: 240000,
    crossdomain: true,
    headers:
    {
        'Content-Type': 'application/json'
    },
});
// export const ativarRefreshToken = (setToken) => {
//     const expires_in =  localStorage.getItem('expires-in-uni');
//     setTimeout(async () => {
//         const response = await axiosApi.post('/autenticacao/refresh');
//         const { access_token, expires_in } = response.data;
//         localStorage.setItem('token-orto', access_token);
//         localStorage.setItem('expires-in-uni', expires_in);
//         axiosApi.defaults.headers.common['authorization'] = `bearer ${access_token}`;
//         setToken(access_token);
//     } , (expires_in - 90000));
// }

axiosRetry(axiosApi, { retries: 3 });