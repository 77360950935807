import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, Row, Col, Table } from 'reactstrap'
import { axiosApi } from '../../Basicos/axiosInstances';
import TabelaCargaHorariaModulo from './TabelaCargaHorariaModulo/TabelaCargaHorariaModulo';
import TabelaCargaHorariaTipoAtividade from './TabelaCargaHorariaTipoAtividade/TabelaCargaHorariaTipoAtividade';

const ModalDetalhesCargaHoraria = ({ isOpen, setIsOpen, className, id, periodoletivo_id, tipoModal }) => {

    const [chModulos, setChModulos] = useState();
    const [chTiposAtividade, setChTiposAtividade] = useState([]);

    const toggle = () => setIsOpen(!isOpen);

    const loadCargaHoraria = async () => {
        const response = await axiosApi.get(`semana-padrao/cadastro-de-ofertas/carga-horaria/${id}?periodo_letivo_id=${periodoletivo_id}`)
        setChModulos(response.data.chModulos)
        setChTiposAtividade(response.data.chTiposAtividade)
    }

    useEffect(() => {
        if (isOpen) {
            loadCargaHoraria()
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={className} size='xl'>
            <ModalBody>
                <Row noGutters>
                    <Col>
                        {tipoModal === 'Modulos' ?
                            <TabelaCargaHorariaModulo chModulos={chModulos} /> :
                            <TabelaCargaHorariaTipoAtividade chTiposAtividade={chTiposAtividade} />
                        }
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalDetalhesCargaHoraria;