import * as actionTypes from '../Actions/tiposActions';
import { setStateRedux } from '../setStateRedux';

const initialState = {
    token: undefined,
    refreshToken: undefined,
    erro: null,
    loader: false,
    autenticado: false,
    redirecionar: '/login',
    sidebar: undefined,
    verificandoLocal: true,
    tokenRecuperacaoValido: false,
    cursoId: undefined,
    periodoLetivoId: undefined
};

const iniciarAutenticao = (state, action) => {
    return setStateRedux(state, { erro: null, loader: true, verificandoLocal: action.verificandoLocal });
};

const autenticacaoAceita = (state, action) => {
    return setStateRedux(state, {
        token: action.token,
        refreshToken: action.refreshToken,
        sidebar: action.sidebar,
        redirecionar: '/',
        erro: null,
        loader: false,
        verificandoLocal: false
    });
};

const autenticacaoRejeitada = (state, action) => {
    return setStateRedux(state, {
        erro: action.erro,
        loader: false,
        redirecionar: '/login',
        verificandoLocal: false
    });
};

const alterarEstadoRecuperacaoToken = (state, action) => {
    return setStateRedux(state, { loader: action.loader, tokenRecuperacaoValido: action.valido });
}
const autenticacaoLogout = (state, action) => {
    return setStateRedux(state, { token: null, redirecionar: '/login', verificandoLocal: false, loader: false });
};

const redirecionar = (state, action) => {
    return setStateRedux(state, { redirecionar: action.url })
}

const setCurso = (state, action) => {
    return setStateRedux(state, { cursoId: action.cursoId })
}

const setPeriodoLetivo = (state, action) => {
    return setStateRedux(state, { periodoLetivoId: action.periodoLetivoId });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INICIO_AUTH:
            return iniciarAutenticao(state, action);
        case actionTypes.SUCESSO_AUTH:
            return autenticacaoAceita(state, action);
        case actionTypes.FALHOU_AUTH:
            return autenticacaoRejeitada(state, action);
        case actionTypes.LOGOUT_AUTH:
            return autenticacaoLogout(state, action);
        case actionTypes.REDIRECIONAR_AUTH:
            return redirecionar(state, action);
        case actionTypes.ESTADO_RECUPERACAO_TOKEN:
            return alterarEstadoRecuperacaoToken(state, action);
        case actionTypes.CURSO:
            return setCurso(state, action);
        case actionTypes.PERIODO_LETIVO:
            return setPeriodoLetivo(state, action);
        default:
            return state;
    }
};

export default reducer;