import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ButtonGroup from '../../Basicos/ButtonGroup';

const BarGraph = ({ title, buttons = true, data = [] }) => {
    const [period, setPeriod] = useState(0);

    const handleOnClick = (event) => {
        setPeriod(event.target.value);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '1rem' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', paddingBottom: '1rem', paddingTop: '1rem' }}>
                <span className="font-weight-bold azul-principal" style={{ fontSize: "25px" }}>
                    {title}
                </span>
                {buttons ? <ButtonGroup
                    buttons={["Últimos 7 dias", "Últimos 30 dias", "Últimos 6 meses", "Últimos 12 meses"]}
                    doSomethingAfterClick={handleOnClick}
                /> : ''}
            </div>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={600}
                    height={300}
                    data={data[period]}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}

                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" style={{
                        fontSize: '1rem'
                    }} />
                    <YAxis yAxisId="left" style={{
                        fontSize: '0.8rem'
                    }} />
                    <Tooltip />
                    <Line yAxisId="left" dataKey="acessos" name="Acessos" stroke="#19385D" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default BarGraph;

