import React from 'react';
import { ModalConsulta } from '.';
import { CardCalendarioPadrao } from '../Conteudo/Cards';

function ModalConsultaHorarioSemanaPadrao(props) {
    const {data} = props;
    const dadosSemanaPadrao = data?.modal?.semanaPadrao;
    const {semanapadrao, tema, tiposDeAtividades} = dadosSemanaPadrao;

    const horariosDefinidos = semanapadrao ? semanapadrao.horarios : [];
    const getHorariosDoCurso = () => tema?.curso?.horarios ?? [];
    const getTipoDeAtividadeDoTema = () => tiposDeAtividades ?? [];
    
    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"
            {...props}
        >
            <section style={{width: "100%"}}>
                <CardCalendarioPadrao
                    className="h-100"
                    horariosDefinidos={horariosDefinidos}
                    horariosCurso={getHorariosDoCurso()}
                    tipoAtividades={getTipoDeAtividadeDoTema()}
                    temMenu={true}  
                    éConsulta={true}
                />
            </section>
        </ModalConsulta>
    );  
}

export default ModalConsultaHorarioSemanaPadrao;