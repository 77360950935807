import React, { Fragment, useState } from 'react';
import { Col, Button } from "reactstrap";
import { SelectForm } from "../../Basicos/index";

const Filtros = ({ novoOnClick, filtros, dados, setDadosFiltrados, valoresFiltro, setValoresFiltro }) => {
    const { cursos, semestres, temas, tipos_de_atividade } = filtros

    const cursosOptions = cursos && cursos.map(curso => ({ value: curso.id, label: curso.nome }))
    const semestresOptions = semestres && semestres.map(semestre => ({ value: semestre.id, label: semestre.descricao, curso_id: semestre.curso_id }))
    const temasOptions = temas && temas.map(tema => ({ value: tema.id, label: tema.nome, semestre_id: tema.semestre_id }))
    const tipoAtividadeOptions = tipos_de_atividade && tipos_de_atividade.map(tipo => ({ value: tipo.id, label: tipo.nome, curso_id: tipo.curso_id }))

    const style = {
        col4: { xs: 6, md: 3, sm: 3, lg: 3, xl: 3 },
        col6: { xs: 6, md: 2, sm: 2, lg: 2, xl: 2 }
    }

    const onChangeSelect = (nome, valor) => {
        switch (nome) {
            case 'curso_id':
                setValoresFiltro({ ...valoresFiltro, curso_id: valor })
                const filtraCurso = dados.filter(dado => (dado.curso_id == valor))
                setDadosFiltrados(filtraCurso)
                break;
            case 'semestre_id':
                setValoresFiltro({ ...valoresFiltro, semestre_id: valor })
                const filtraSemestre = dados.filter(dado => (
                    dado.curso_id == valoresFiltro.curso_id
                    && dado.semestre_id == valor))
                setDadosFiltrados(filtraSemestre)
                break;
            case 'tema_id':
                setValoresFiltro({ ...valoresFiltro, tema_id: valor })
                const filtraTema = dados.filter(dado => (
                    dado.curso_id == valoresFiltro.curso_id
                    && dado.semestre_id == valoresFiltro.semestre_id
                    && dado.tema_id == valor))
                setDadosFiltrados(filtraTema)
                break;
            case 'tipo_de_atividade_id':
                setValoresFiltro({ ...valoresFiltro, tipo_de_atividade_id: valor })
                const filtraTipoAtividade = dados.filter(dado => (
                    dado.curso_id == valoresFiltro.curso_id
                    && dado.semestre_id == valoresFiltro.semestre_id
                    && dado.tema_id == valoresFiltro.tema_id
                    && dado.tipo_de_atividade_id == valor))
                setDadosFiltrados(filtraTipoAtividade)
            default:
                break;
        }
    }

    return (
        <Fragment>
            <SelectForm
                label="Curso"
                name="curso_id"
                placeholder="Curso"
                options={cursosOptions}
                onChange={onChangeSelect}
                value={cursosOptions && cursosOptions.find(option => (option.value === valoresFiltro.curso_id)) || ''}
                style={{ col: style.col6 }}
            />
            <SelectForm
                label="Semestre"
                name="semestre_id"
                placeholder="Semestre"
                options={semestresOptions && semestresOptions.filter(option => (option.curso_id === valoresFiltro.curso_id))}
                value={semestresOptions && semestresOptions.find(option => (option.value === valoresFiltro.semestre_id && option.curso_id === valoresFiltro.curso_id)) || ''}
                onChange={onChangeSelect}
                readOnly={!valoresFiltro.curso_id}
                style={{ col: style.col6 }}
            />
            <SelectForm
                label="Tema"
                name="tema_id"
                placeholder="Tema"
                options={temasOptions && temasOptions.filter(option => (option.semestre_id === valoresFiltro.semestre_id))}
                value={temasOptions && temasOptions.find(option => (option.value === valoresFiltro.tema_id && option.semestre_id === valoresFiltro.semestre_id)) || ''}
                onChange={onChangeSelect}
                readOnly={!valoresFiltro.semestre_id}
                style={{ col: style.col4 }}
            />
            <SelectForm
                label="Tipo de Atividade"
                name="tipo_de_atividade_id"
                placeholder="Atividade"
                options={tipoAtividadeOptions && tipoAtividadeOptions.filter(option => (option.curso_id === valoresFiltro.curso_id))}
                onChange={onChangeSelect}
                readOnly={!valoresFiltro.tema_id}
                style={{ col: style.col4 }}
            />
            <Col className="btn-limpar-filtro">
                <Button className="mx-3 py-2"
                    onClick={() => (
                        setValoresFiltro({
                            curso_id: undefined,
                            semestre_id: undefined,
                            tema_id: undefined,
                            tipo_de_atividade_id: undefined
                        }),
                        setDadosFiltrados(dados)
                    )}>Limpar</Button>
            </Col>
            <Col className="btn-limpar-filtro">
                <Button className="mx-3 py-2" onClick={novoOnClick}>Novo</Button>
            </Col>
        </Fragment >)
}

export default Filtros