import React from 'react'
import {Col, Row , Button} from 'reactstrap'

export default class VerificarErroInterno extends React.Component {
    constructor(props) {
        super(props);
        this.state = { temErro: false };
    }

    static getDerivedStateFromError(error) {
        return { temErro: true };
    }

    componentDidCatch(error, info) {
       // tiver um serviço para log..
    }

    render() {
        return  this.state.temErro ? (
            <Row noGutters={true} className='align-content-middle h-100vh'>
                <Col sm={12} className='texto-secundario div-text-middle'>
                    <h1>Ooops, algum erro aconteceu....</h1>
                    <p>Caso persista, contate seu Administrador/Softbuilder!</p>
                    <Button className="btn-secundario"
                            onClick ={() => window.location.reload()}>
                        Recarregar pagina
                    </Button>
                </Col>
            </Row>
        ) : (
            this.props.children
        )
    }
}