import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { axiosApi } from '../../axiosInstances';

const ModalDisponibilidadeTroca = ({ dispProfessor, setDispProfessor, HorarioAulas, onSubmit }) => {
    const toggle = () => (
        setDispProfessor({
            ...dispProfessor,
            isOpen: !dispProfessor.isOpen
        }));
    const disponibilidades = dispProfessor.dadosDisponibilidade.filter(disp => disp.horario_id == HorarioAulas.horario_curso.horario_id);
    const divergencias = disponibilidades.map(professor => {
        const divergenciaDisponibilidade = professor.horario_id == HorarioAulas.horario_curso.horario_id && (professor.tipo_de_disponibilidade_id == 3 || professor.tipo_de_disponibilidade_id == undefined);
        return {
            professor_id: professor.professor_id,
            professor: professor.nome_social,
            horario: professor.inicio,
            descricao: professor.descricao,
            divergencia: divergenciaDisponibilidade
        }
    })
    return (
        <Modal isOpen={dispProfessor.isOpen} toggle={toggle}>
            <ModalHeader className="align-content-middle">
                Disponibilidade Professor - Confirmar Mudança
            </ModalHeader>
            <ModalBody>
                <Table id="tabela-divergencia-troca-aula" hover>
                    <thead>
                        <tr>
                            <th>Professor</th>
                            <th>Horário</th>
                            <th>Disponibilidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {divergencias.map(divergencia => (
                            <tr key={divergencia.professor_id} className="text-center">
                                <td>{divergencia.professor}</td>
                                <td>{divergencia.horario.substring(0, 5)}</td>
                                <td>{divergencia.descricao}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row className="align-content-middle">
                    <Button onClick={onSubmit}>Confirmar</Button>
                    <Button onClick={toggle}>Cancelar</Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}

const TabelaTrocarAula = ({
    toggle,
    HorarioAulas,
    aulaSelecionada,
    aulasPossiveis,
    horariosOfertas,
    setHorariosOfertas,
    ativar
}) => {

    const [dadosForm, setDadosForm] = useState({
        aulaAtual: {
            aula_id: undefined,
            horario_aula_id: undefined,
            horario_oferta_periodo_id: undefined
        },
        aulaTroca: {
            aula_id: undefined,
            horario_aula_id: undefined,
            horario_oferta_periodo_id: undefined
        },
        atualIndex: undefined,
        novaIndex: undefined,
    });

    const [dispProfessor, setDispProfessor] = useState({
        isOpen: false,
        dadosDisponibilidade: []
    });

    const selecionarAula = (event) => {
        const { id, name, value, checked } = event.target
        const aula = aulasPossiveis.find(aula => {
            return aula.horario_oferta_perido_id == value && aula.aula_id == id;
        });
        const aulaAtualIndex = horariosOfertas.findIndex(horario => (horario.id == HorarioAulas.id))
        const aulaNovaIndex = horariosOfertas.findIndex(horario => (horario.id == aula.horario_oferta_perido_id))
        setDadosForm({
            ...dadosForm,
            aulaTroca: {
                aula_id: aula.aula_id,
                horario_aula_id: aula.horario_aula_id,
                horario_oferta_periodo_id: aula.horario_oferta_perido_id,
            },
            atualIndex: aulaAtualIndex,
            novaIndex: aulaNovaIndex,
        })
    }

    const onSubmit = async () => {
        try {
            const response = await axiosApi.post('aulas/trocar-aulas', dadosForm);
            setDispProfessor({ ...dispProfessor, isOpen: false })
            const horarioOfertaAux = horariosOfertas.slice();

            const horariosSemAulasTroca = horarioOfertaAux.filter(elemento => {
                const horarioAtual = horarioOfertaAux[dadosForm.atualIndex];
                const horarioTroca = horarioOfertaAux[dadosForm.novaIndex];
                return !(elemento.data == horarioTroca.data && elemento.horario_do_curso_id == horarioTroca.horario_do_curso_id)
                    && !(elemento.data == horarioAtual.data && elemento.horario_do_curso_id == horarioAtual.horario_do_curso_id)
            })
            //Insere as Aulas Já atualizadas
            setHorariosOfertas([...horariosSemAulasTroca, ...response.data.horarioOfertaTroca, ...response.data.horarioOfertaAtuais]);
            toggle();
        } catch (error) {
            if (error.response.status === 500)
                ativar(400, error?.response?.data?.erro);
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }
            console.log(error?.response)
        }
    }

    const detalhesTroca = async () => {
        const { professores_ids, dia_da_semana } = aulasPossiveis.find(aula => (aula.aula_id == dadosForm.aulaTroca.aula_id))
        try {
            const response = await axiosApi.get(`aulas/trocar-aulas?professores_ids=${professores_ids}&dia_da_semana=${dia_da_semana}`)
            setDispProfessor({ dadosDisponibilidade: response.data.dados, isOpen: true });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (aulaSelecionada !== undefined) {
            setDadosForm({
                ...dadosForm,
                aulaAtual: {
                    aula_id: aulaSelecionada.aulas_id,
                    horario_aula_id: aulaSelecionada.id,
                    horario_oferta_periodo_id: aulaSelecionada.horario_oferta_perido_id,
                }

            })
        }
    }, [aulaSelecionada])

    return (
        <Col>
            <ModalDisponibilidadeTroca dispProfessor={dispProfessor} setDispProfessor={setDispProfessor} HorarioAulas={HorarioAulas} onSubmit={onSubmit} />
            <Table hover id="tabela-trocar-aula">
                <thead>
                    <tr>
                        <th colSpan="6">
                            Distribuição de Carga Horária do Tema por Tipo de Atividade
                        </th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>Horário</th>
                        <th>Aula</th>
                        <th>Professores</th>
                        <th>Tipo de Atividade</th>
                        <th>Duração</th>
                    </tr>
                </thead>
                <tbody>
                    {aulasPossiveis.map((aula, index) => (
                        <tr key={aula.aula_id + '-' + index}>
                            <td><input type="radio" name="aula_id" value={aula.horario_oferta_perido_id} id={aula.aula_id} onChange={selecionarAula} /></td>
                            <td>{aula.horario.substring(0, 5)}</td>
                            <td>{aula.titulo}</td>
                            <td>
                                <ul>
                                    {aula.professores.split(",").map((professor, index) => (
                                        <li key={aula.aula_id + '-' + index}>{professor}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>{aula.tipo_atividade}</td>
                            <td>{aula.duracao_minima}</td>
                        </tr>
                    ))}
                    {!aulasPossiveis.length &&
                        <tr>
                            <td colSpan="6">
                                Selecione uma data
                            </td>
                        </tr>}
                </tbody>
            </Table>
            <Button onClick={detalhesTroca} disabled={dadosForm.aulaTroca.aula_id === undefined || aulaSelecionada === undefined}>Confirmar</Button>
            <Button onClick={toggle}>Cancelar</Button>
        </Col>
    )
}

export default TabelaTrocarAula;