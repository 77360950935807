const separandoHexadecimalEmCoresRGB = hexadecimal => {
    let r = 0, g = 0, b = 0;
    

    //#AAA
    if (hexadecimal.length === 4) {
        r = `0x${hexadecimal[1]}${hexadecimal[1]}`;
        g = `0x${hexadecimal[2]}${hexadecimal[3]}`;
        b = `0x${hexadecimal[2]}${hexadecimal[3]}`;
    
    //#AAAAAA
    } else if (hexadecimal.length === 7) {
        r = `0x${hexadecimal[1]}${hexadecimal[2]}`;
        g = `0x${hexadecimal[3]}${hexadecimal[4]}`;
        b =`0x${hexadecimal[5]}${hexadecimal[6]}`;
    }

    r = parseInt(r);
    g = parseInt(g);
    b = parseInt(b)
    
    return {r, g, b}
}

const somaDasCoresEmRGB = ({coresRGB}) => coresRGB.r + coresRGB.g + coresRGB.b;

export const corDoTexto = (hexadecimal) => {
    let coresRGB = separandoHexadecimalEmCoresRGB(hexadecimal);

    if(coresRGB.g > 208)
        return "black";

    let somaDasCores = somaDasCoresEmRGB({coresRGB})
    
    if(somaDasCores > 382)
        return "black";
    return "white";
}