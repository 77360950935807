import { NavItem, NavLink } from 'reactstrap'
import React from 'react'

const NavItemCustom = (props) => {
    return (
        <NavItem>
            <NavLink active={props.activeTab === props.id} onClick={props.onClick}>
                <label className={props.classname}>{props.descricao}</label>
            </NavLink>
        </NavItem>
    )
}

export default NavItemCustom
