import React from 'react'
import { Table } from 'reactstrap'

const TabelaCargaHorariaTipoAtividade = ({ chTiposAtividade }) => {

    const tipos_vertical = chTiposAtividade.filter(tpatividade => (tpatividade.modulo === 'Vertical'))
    const tipos_horizontal = chTiposAtividade.filter(tpatividade => (tpatividade.modulo === 'Horizontal'))

    const totais = {
        alocadas: 0,
        qtd_quadrantes_aula: 0,
        carga_horaria_prevista: 0,
        quadrantes_distribuidos: 0
    }
    
    chTiposAtividade.map(elemento => (
        totais.alocadas += parseInt(elemento.alocadas),
        totais.qtd_quadrantes_aula += parseInt(elemento.qtd_quadrantes_aula),
        totais.carga_horaria_prevista += parseInt(elemento.carga_horaria_aulas),
        totais.quadrantes_distribuidos += parseInt(elemento.quadrantes_distribuidos)
    ))

    const subHeader = {
        background: '#f2f2f2',
        textAlign: 'center',
    }

    return (
        <Table hover id="tabela-carga-tipo-atividade">
            <thead>
                <tr>
                    <th colSpan="7">
                        Distribuição de Carga Horária do Tema por Tipo de Atividade
                    </th>
                </tr>
                <tr>
                    <th>Tipos de Atividades</th>
                    <th>Carga Horária Prevista</th>
                    <th>Carga Horária Alocada</th>
                    <th>Carga Horária a ser Alocada</th>
                    <th>Quadrantes Distribuidos</th>
                    <th>Quadrantes Com Aula</th>
                    <th>Quadrantes Sem Aula</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="7" style={subHeader}><strong>Vertical</strong></td>
                </tr>
                {tipos_vertical.map((vertical, index) => (
                    <tr key={index}>
                        <td>{vertical.tipo_atividade}</td>
                        <td>{vertical.carga_horaria_aulas}</td>
                        <td>{vertical.alocadas}</td>
                        <td>{vertical.carga_horaria_aulas - vertical.alocadas}</td>
                        <td>{vertical.quadrantes_distribuidos}</td>
                        <td>{vertical.qtd_quadrantes_aula}</td>
                        <td>{vertical.quadrantes_distribuidos - vertical.qtd_quadrantes_aula}</td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="7" style={subHeader}><strong>Horizontal</strong></td>
                </tr>
                {tipos_horizontal.map((horizontal, index) => (
                    <tr key={index}>
                        <td>{horizontal.tipo_atividade}</td>
                        <td>{horizontal.carga_horaria_aulas}</td>
                        <td>{horizontal.alocadas}</td>
                        <td>{horizontal.carga_horaria_aulas - horizontal.alocadas}</td>
                        <td>{horizontal.quadrantes_distribuidos}</td>
                        <td>{horizontal.qtd_quadrantes_aula}</td>
                        <td>{horizontal.quadrantes_distribuidos - horizontal.qtd_quadrantes_aula}</td>
                    </tr>
                ))}
                <tr style={subHeader}>
                    <td><strong>Total</strong></td>
                    <td><strong>{totais.carga_horaria_prevista}</strong></td>
                    <td><strong>{totais.alocadas}</strong></td>
                    <td><strong>{totais.carga_horaria_prevista - totais.alocadas}</strong></td>
                    <td><strong>{totais.quadrantes_distribuidos}</strong></td>
                    <td><strong>{totais.qtd_quadrantes_aula}</strong></td>
                    <td><strong>{totais.quadrantes_distribuidos - totais.qtd_quadrantes_aula}</strong></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default TabelaCargaHorariaTipoAtividade;