import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap';
import { MenuDasFuncionalidades } from "../Menu";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import AlunosIcon from '../Icons/Alunos';
import ProfessorIcon from '../Icons/Professor';
import UsuariosIcon from '../Icons/Usuarios';
import BarGraph from '../Conteudo/Graficos/BarGraph';
import { axiosApi } from '../Basicos/axiosInstances';
import { NewLoader } from '../Basicos';


function Dashbord({
    ativarAlert
}) {
    const [cursos, setCursos] = useState([]);
    const [periodosLetivos, setPeriodosLetivos] = useState([]);
    const [semestres, setSemestres] = useState([]);
    const [dadosFiltrados, setDadosFiltrados] = useState([]);
    const [count, setCount] = useState([]);
    const [acessosAlunos, setAcessosAlunos] = useState([]);
    const [acessosProfessores, setAcessosProfessores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [valoresFiltro, setValoresFiltro] = useState({
        curso_id: 17,
        periodo_letivo_id: 5,
        semestre_id: undefined
    })

    const getDadosDashboard = async () => {
        try {
            setIsLoading(true);
            const { data } = await axiosApi.post('/dashboards', valoresFiltro)
            const { count, acessos } = data.dados
            setCount(count);
            setAcessosAlunos(acessos?.alunos);
            setAcessosProfessores(acessos?.professores);
        } catch (error) {
            console.log(error)
            if (error.response.status === 403) {
                ativarAlert(400, error?.response?.data?.mensagem);
            }
        } finally {
            setIsLoading(false);
        }
    }
    const getOptions = async () => {
        try {
            const { data } = await axiosApi.get('/dashboards/options')
            const { semestre, periodo, cursos } = data.dados
            setSemestres(semestre);
            setPeriodosLetivos(periodo);
            setCursos(cursos);
        } catch (error) {
            console.log(error)
        }
    }

    const iconCard = (index) => {
        switch (index) {
            case 0:
                return <AlunosIcon width="3rem" height="3rem" color='white' />
            case 1:
                return <ProfessorIcon width="3rem" height="3rem" color='white' />
            case 2:
                return <UsuariosIcon width="4rem" height="4rem" color='white' />
            default:
                break;
        }
    }

    useEffect(() => { getDadosDashboard() }, [valoresFiltro])
    useEffect(() => { getOptions() }, [])

    // console.log(valoresFiltro)
    return (
        <Row noGutters>
            <MenuDasFuncionalidades
                funcionalidade={"dashboard"}
                classNavBar="navbar-consultas"
                titulo="Dashboard"
                options={[
                    cursos,
                    periodosLetivos,
                    semestres
                ]}
                setDadosFiltrados={setDadosFiltrados}
                setValoresFiltro={setValoresFiltro}
                valoresFiltro={valoresFiltro}
                isActive={count && count.length > 0}
            />
            <NewLoader isActive={isLoading} overlay >

                {count && count.length > 0 ? <Col sm={12} md={12} className="col-conteudo">
                    <div style={{ padding: '2.5rem', marginLeft: '1.5rem', marginRight: '1.5rem', display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center' }}>
                        <div style={{ width: '100%', gap: '3rem', display: 'flex', justifyContent: 'space-between' }}>
                            {count?.map((item, index) => {
                                return <div key={index} className='shadow-xl' style={{ 'backgroundColor': "white", borderRadius: '16px', width: '33%', height: '10rem', boxShadow: '0px 1px 3px 0px #00000033', display: 'flex', alignItems: 'center', padding: '1rem', gap: '1rem' }}>
                                    <div style={{ 'backgroundColor': '#19385D', borderRadius: '16px', width: '6rem', height: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {iconCard(index)}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="font-weight-bold" style={{ fontSize: "17px", marginTop: '0.5rem' }}>
                                            {item?.label ?? ''}
                                        </span>
                                        <span className="font-weight-bold azul-principal" style={{ fontSize: "50px" }}>
                                            {item?.count ?? ''}
                                        </span>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className='shadow-xl' style={{ 'backgroundColor': "white", borderRadius: '16px', width: '100%', height: '30rem', boxShadow: '0px 1px 3px 0px #00000033', display: 'flex', alignItems: 'center', padding: '1rem' }}>
                            <BarGraph
                                title={'Acessos do Aluno'}
                                buttons={true}
                                data={acessosAlunos}
                            />
                        </div>
                        <div className='shadow-xl' style={{ 'backgroundColor': "white", borderRadius: '16px', width: '100%', height: '30rem', boxShadow: '0px 1px 3px 0px #00000033', display: 'flex', alignItems: 'center', padding: '1rem' }}>
                            <BarGraph
                                title={'Acessos do Professor'}
                                buttons={true}
                                data={acessosProfessores}
                            />
                        </div>
                    </div>
                </Col> : ""}
            </NewLoader>

        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.subFuncionalidades.dashboard,
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashbord)