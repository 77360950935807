import React from 'react'
function Calendario({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
            version="1.0"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 65 65"
            style={style}
            className={className}
        >
            <g
                fill={color}
                stroke="none"
            >
                <path  d="M 58.738281 5.042969 L 49.738281 5.042969 L 49.738281 2.789062 C 49.738281 1.546875 48.730469 0.535156 47.488281 0.535156 C 46.242188 0.535156 45.234375 1.546875 45.234375 2.789062 L 45.234375 5.042969 L 18.226562 5.042969 L 18.226562 2.789062 C 18.226562 1.546875 17.214844 0.535156 15.972656 0.535156 C 14.726562 0.535156 13.71875 1.546875 13.71875 2.789062 L 13.71875 5.042969 L 4.71875 5.042969 C 2.234375 5.042969 0.214844 7.046875 0.214844 9.535156 L 0.214844 59.0625 C 0.214844 61.546875 2.234375 63.566406 4.71875 63.566406 L 42.992188 63.566406 C 43.597656 63.566406 44.164062 63.332031 44.582031 62.902344 L 62.59375 44.890625 C 63.011719 44.472656 63.257812 43.894531 63.257812 43.304688 L 63.257812 9.535156 C 63.246094 7.0625 61.226562 5.042969 58.738281 5.042969 Z M 44.890625 57.054688 L 44.890625 45.003906 L 56.976562 45.003906 Z M 59.304688 41.050781 L 45.234375 41.050781 C 42.746094 41.050781 40.726562 43.070312 40.726562 45.554688 L 40.726562 59.628906 L 4.152344 59.628906 L 4.152344 23.050781 L 59.304688 23.050781 Z M 58.738281 19.113281 L 4.152344 19.113281 L 4.152344 8.980469 L 13.71875 8.980469 L 13.71875 11.800781 C 13.71875 13.042969 14.726562 14.054688 15.972656 14.054688 C 17.214844 14.054688 18.226562 13.042969 18.226562 11.800781 L 18.226562 8.980469 L 45.234375 8.980469 L 45.234375 11.800781 C 45.234375 13.042969 46.242188 14.054688 47.488281 14.054688 C 48.730469 14.054688 49.738281 13.042969 49.738281 11.800781 L 49.738281 8.980469 L 59.304688 8.980469 L 59.304688 19.113281 Z M 58.738281 19.113281 " />
            </g>
        </svg>
    )
};

export default Calendario;