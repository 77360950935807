import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { AlertReducer, SubFuncionalidadesReducer, AuthReducer, EmpresasReducer, CoreReducer} from './componentes/Store/Reducers/index'

const rootReducer = combineReducers ({
    alert: AlertReducer,
    empresas: EmpresasReducer,
    auth: AuthReducer,
    core: CoreReducer,
    subFuncionalidades: SubFuncionalidadesReducer,
})


const store = createStore(rootReducer, compose(applyMiddleware(thunk)))
ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
