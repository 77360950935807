import React from 'react'
function Dashboard({ style, className, width = "55px", height = "57px", color = "" }) {
	return (
		<svg
			version="1.0"
			x="0px"
			y="0px"
			width={width}
			height={height}
			viewBox="0 0 65 65"
			style={style}
			className={className}
		>
			<g
				fill={color}
				stroke="none"
			>
				<path d="M 60.613281 38.59375 C 60.613281 38.503906 60.570312 38.421875 60.558594 38.335938 C 60.542969 38.199219 60.523438 38.070312 60.476562 37.941406 C 60.433594 37.8125 60.371094 37.703125 60.308594 37.589844 C 60.246094 37.480469 60.183594 37.375 60.101562 37.277344 C 60.015625 37.175781 59.914062 37.097656 59.8125 37.015625 C 59.710938 36.941406 59.613281 36.863281 59.5 36.804688 C 59.378906 36.746094 59.25 36.707031 59.117188 36.671875 C 59.03125 36.648438 58.960938 36.597656 58.871094 36.585938 C 58.820312 36.578125 58.773438 36.597656 58.722656 36.59375 C 58.671875 36.585938 58.628906 36.5625 58.582031 36.5625 L 28.4375 36.5625 L 28.4375 6.417969 C 28.4375 6.371094 28.414062 6.324219 28.410156 6.277344 C 28.40625 6.226562 28.425781 6.183594 28.417969 6.132812 C 28.40625 6.046875 28.355469 5.976562 28.332031 5.890625 C 28.296875 5.757812 28.257812 5.625 28.195312 5.503906 C 28.136719 5.390625 28.0625 5.292969 27.988281 5.195312 C 27.902344 5.085938 27.820312 4.988281 27.71875 4.898438 C 27.625 4.816406 27.527344 4.761719 27.421875 4.699219 C 27.300781 4.628906 27.183594 4.5625 27.046875 4.515625 C 26.929688 4.476562 26.804688 4.460938 26.679688 4.445312 C 26.585938 4.429688 26.503906 4.386719 26.40625 4.386719 C 26.359375 4.386719 26.3125 4.410156 26.265625 4.414062 C 26.214844 4.421875 26.171875 4.398438 26.121094 4.40625 C 11.230469 6.511719 0 19.460938 0 34.53125 C 0 51.332031 13.667969 65 30.46875 65 C 45.539062 65 58.488281 53.769531 60.59375 38.878906 C 60.601562 38.828125 60.578125 38.785156 60.585938 38.734375 C 60.589844 38.6875 60.613281 38.640625 60.613281 38.59375 Z M 30.46875 60.9375 C 15.910156 60.9375 4.0625 49.089844 4.0625 34.53125 C 4.0625 22.28125 12.667969 11.753906 24.375 8.976562 L 24.375 38.59375 C 24.375 39.714844 25.285156 40.625 26.40625 40.625 L 56.023438 40.625 C 53.25 52.332031 42.722656 60.9375 30.46875 60.9375 Z M 30.46875 60.9375 " />
				<path d="M 34.53125 0 C 33.992188 0 33.476562 0.214844 33.09375 0.59375 C 32.710938 0.972656 32.5 1.496094 32.5 2.03125 L 32.507812 30.476562 C 32.507812 31.015625 32.722656 31.53125 33.101562 31.914062 C 33.480469 32.296875 34.003906 32.507812 34.539062 32.507812 L 62.96875 32.5 C 64.089844 32.5 65 31.589844 65 30.46875 C 65 13.667969 51.332031 0 34.53125 0 Z M 36.570312 28.445312 L 36.5625 4.140625 C 49.507812 5.132812 59.867188 15.496094 60.859375 28.4375 Z M 36.570312 28.445312 " />
			</g>
		</svg>
	)
};

export default Dashboard;
