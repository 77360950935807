import React from 'react'
import PropTypes from 'prop-types'
import { Col, CustomInput, FormGroup, Label } from "reactstrap"

/*****************************************************************************************************************************************/
//Componente responsavel por configurar um input do reactstrap de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/***************************************************************************************************************************************/

const CheckBoxGroup = (props) => {

    const onChange = (event) => {
        props.onChange(event.target.name, event.target.checked)
    }
    return (
        <Col {...props.style.col} className="form-col">
            {props.title !== undefined ? <Label><b className="label-titulo-consultas">{props.title}</b></Label> : ""}
            <FormGroup className={(props.hidden ? "hidden" : "")}>
                <Label className={props.style.labelClass}>
                    {props.label}
                </Label>
                <div className="d-flex">
                    {props.checkboxs.map((elemento, indice) => (
                        <>
                            <CustomInput
                                type="checkbox"
                                innerRef={elemento.innerRef}
                                name={elemento.name}
                                disabled={elemento.disabled}
                                className={"ml-5"}
                                onChange={onChange}
                                id={elemento.name + indice + Math.floor(Math.random() * 500)}
                                label={elemento.label}
                                inline={elemento.inline}
                                checked={elemento.checked}
                                defaultChecked={elemento.valorPadrao}
                            />
                        </>
                    ))}
                </div>
                {props.checkboxs2 != null ?
                    <div className="d-flex mt-2">
                        {props.checkboxs2.map((elemento, indice) => (
                            <CustomInput
                                type="checkbox"
                                innerRef={elemento.innerRef}
                                name={elemento.name}
                                disabled={elemento.disabled}
                                className={"ml-5"}
                                onChange={onChange}
                                id={elemento.name + indice + Math.floor(Math.random() * 500)}
                                label={elemento.label}
                                inline={elemento.inline}
                                checked={elemento.checked}
                                defaultChecked={elemento.valorPadrao}
                            />
                        ))}
                    </div> : ""
                }
                {props.checkboxs3 != null ?
                    <div className="d-flex mt-2">
                        {props.checkboxs3.map((elemento, indice) => (
                            <CustomInput
                                type="checkbox"
                                innerRef={elemento.innerRef}
                                name={elemento.name}
                                disabled={elemento.disabled}
                                className={"ml-5"}
                                onChange={onChange}
                                id={elemento.name + indice + Math.floor(Math.random() * 500)}
                                label={elemento.label}
                                inline={elemento.inline}
                                checked={elemento.checked}
                                defaultChecked={elemento.valorPadrao}
                            />
                        ))}
                    </div> : ""
                }

            </FormGroup>
        </Col>
    )
}

export default CheckBoxGroup

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

CheckBoxGroup.defaultProps = {
    placeholder: "",
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        Col: {
            xs: 12,
            sm: 12,
            md: null,
            lg: null,
            xl: null
        }

    }
};

CheckBoxGroup.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checkboxs: PropTypes.array.isRequired,
    indice: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}