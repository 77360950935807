import React, {useEffect , useState} from 'react'
import PropTypes from 'prop-types'
import { FiCheckSquare, FiXSquare } from 'react-icons/fi';

import { Col } from 'reactstrap';

/**
 * 
 * Usado apenas pela avine, caso venha a ser usado por outras deve passar a ser dinamico.
 * Usar o comparacaoString para definir o numero de ativos
 * 
 */

const ControleSelecao = ({ sm, md, dados=[], numeroDeSelecionados=0, colClassName, colStyle, definirOnclick, ativo }) => {
    
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let aux = 0;
        dados.forEach((elemento) => {
            if (!(elemento.liberaaprovado || elemento.rejeitado)) {
                aux++;
            }
        });

        setTotal(aux);
    }, [dados.length, dados]);

    return (
        <Col sm={sm} md={md} className={colClassName} style={colStyle}>
            <div className="titulo-header-modal" style={{ width: "calc(100% - 250px)" }}>{numeroDeSelecionados}/{total}</div>

            {total !== numeroDeSelecionados ? (
                <div style={divStyle} onClick={() => definirOnclick({onClick:"gerarTodosOnChange", dados: { marcarTodos: true}})}>
                    <sup style={supStyle}>Selecionar Tudo</sup>
                    <FiCheckSquare color="white" size="1.4em" style={svgStyle} />
                </div> 
            ) : (
                <div style={divStyle} onClick={() => definirOnclick({onClick:"gerarTodosOnChange", dados: { marcarTodos: false}})}>
                    <sup style={supStyle}>Desmarcar Tudo</sup>
                    <FiXSquare color="white" size="1.4em" style={svgStyle} />
                </div> 
            )}
        </Col>
    )
}

const divStyle = {
    cursor: 'pointer',
    float: 'right',
    maxWidth: '200px',
    backgroundColor: '#FFFFFF',
    padding: '0px 10px',
    borderRadius: '20px',
};

const supStyle = {
    fontSize: '20px',
    display: 'contents',
    float: 'left',
    color: 'var(--cor-text-verde)',
};

const svgStyle = {
    color: 'var(--cor-text-verde)',
    float: 'right',
    margin: '1px 0px 0px 8px',
};

ControleSelecao.propTypes = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ControleSelecao.defaultProps = {
    createOnClick: undefined,
    xs: undefined,
    sm: 12,
    md: 12,
    lg: undefined,
    xl: undefined,
    colClassName: "menu-marcacoes-header",
    colStyle: { padding: '7px 10px 2px' },
};

export default ControleSelecao




