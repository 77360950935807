import React from 'react'
function Disciplinas({ style, className, width = "55px", height = "57px", color = "" }) {
    return (
        <svg
            version="1.0"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 65 65"
            style={style}
            className={className}
        >
            <g
                fill={color}
                stroke="none"
            >
                <path  d="M 55.390625 10.433594 L 51.574219 10.433594 L 51.574219 2.652344 C 51.574219 1.382812 50.550781 0.363281 49.285156 0.363281 L 12.476562 0.363281 C 8.4375 0.363281 5.777344 3.648438 5.777344 7.6875 L 5.777344 56.066406 C 5.777344 60.105469 9.066406 63.394531 13.105469 63.394531 L 55.378906 63.394531 C 56.644531 63.394531 57.667969 62.371094 57.667969 61.101562 L 57.667969 12.722656 C 57.679688 11.453125 56.644531 10.433594 55.390625 10.433594 Z M 12.476562 4.300781 L 47.632812 4.300781 L 47.632812 10.433594 L 12.476562 10.433594 C 10.914062 10.433594 9.742188 8.941406 9.742188 7.367188 C 9.742188 5.890625 10.984375 4.300781 12.476562 4.300781 Z M 53.703125 59.453125 L 12.402344 59.453125 C 10.914062 59.453125 9.65625 58.199219 9.65625 56.707031 L 9.730469 13.867188 C 10.578125 14.199219 11.503906 14.382812 12.476562 14.382812 L 53.765625 14.382812 Z M 53.703125 59.453125 " />
                <path  d="M 29.933594 12.796875 L 29.933594 32.691406 L 26.804688 31.199219 C 26.078125 30.757812 25.167969 30.757812 24.441406 31.199219 L 21.328125 32.691406 L 21.328125 12.796875 L 17.386719 12.796875 L 17.386719 35.203125 C 17.386719 36.027344 17.832031 36.789062 18.558594 37.195312 C 19.269531 37.601562 20.15625 37.589844 20.859375 37.160156 L 25.625 34.855469 L 30.398438 37.160156 C 30.757812 37.378906 31.175781 37.492188 31.582031 37.492188 C 31.964844 37.492188 32.355469 37.394531 32.703125 37.195312 C 33.414062 36.789062 33.859375 36.027344 33.859375 35.203125 L 33.859375 12.796875 Z M 29.933594 12.796875 " />
            </g>
        </svg>
    )
};

export default Disciplinas;


