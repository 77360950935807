import * as tiposActions from '../Actions/tiposActions'
import { setStateRedux } from '../setStateRedux'

const initialState = {
    boolAlert: false,
    boolModalConfirm: false,
    tipo: undefined,
    acao: undefined,
    mensagem: undefined,
    callback: undefined,
    titulo: undefined,
    cor: undefined,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case tiposActions.ATIVAR_ALERT:
            return setStateRedux(state, { boolAlert: true, tipo: action.tipo, acao: action.acao, mensagem: action.mensagem, cor: action.cor, })

        case tiposActions.DESATIVAR_ALERT:
            return setStateRedux(state, { boolAlert: false })

        case tiposActions.ATIVAR_MODAL_CONFIRM:
            return setStateRedux(state, { boolModalConfirm: true, mensagem: action.mensagem, titulo: action.titulo, callback: action.callback })

        case tiposActions.DESATIVAR_MODAL_CONFIRM:
            return setStateRedux(state, { boolModalConfirm: false })
        default:
            return state;
    }
}

export default reducer