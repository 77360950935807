import React from 'react'
import PropTypes from 'prop-types'
import { Col, CustomInput, FormGroup } from "reactstrap"

/*****************************************************************************************************************************************/
//Componente responsavel por configurar um input do reactstrap de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/***************************************************************************************************************************************/

const CheckBox = (props) => {

    const onChange = (event) => {
        props.onChange(event.target.name, event.target.checked)
    }

    return (
        <Col {...props.style.col} className="form-col">
            <FormGroup className={(props.hidden ? "hidden" : "")}>
                <CustomInput
                    type="checkbox"
                    innerRef={props.innerRef}
                    name={props.name}
                    disabled={props.disabled}
                    className={props.className}
                    onChange={onChange}
                    id={props.name + props.indice + Math.floor(Math.random() * 500)}
                    label={props.label}
                    inline={props.inline}
                    checked={props.checked}
                    defaultChecked={props.valorPadrao}
                />
            </FormGroup>
        </Col>
    )
}

export default CheckBox

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)

}

CheckBox.defaultProps = {
    placeholder: "",
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        Col: {
            xs: 12,
            sm: 12,
            md: null,
            lg: null,
            xl: null
        }

    }
};

CheckBox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    indice: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}