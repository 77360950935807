import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom";
import { Nav, Collapse } from 'reactstrap';
import { SidebarItem } from '../Basicos/index';
import { FaBars } from 'react-icons/fa';
import { connect } from 'react-redux'
import * as actionsRedux from '../Store/Actions/index'
import { Scrollbars } from "react-custom-scrollbars";
import logoVertical from '../images/logo-unichristus-web.png';
import logoHorizontal from '../images/Supervisor_Logo_Horizontal_Branca_new.png';


function Sidebar(props) {
    return (
        <div
            className={`sidebar navbar-header ${props.sidebarAtivo ? 'active' : ''}`}
            onMouseLeave={props.ativarSidebar}
            onMouseEnter={props.ativarSidebar}
        >
            <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                autoHeight
                autoHeightMin={props.modoMobile && !props.sidebarAtivo ? '75px' : '100vh'}
                autoHeightMax={'100vh'}
            >
                <p className="h4 logoSB navbar-light">
                    <img src={props.modoMobile ? logoHorizontal : logoVertical} />

                    {props.modoMobile && (
                        <FaBars
                            color="white"
                            size="30px"
                            onClick={props.ativarSidebar}
                            className='icon-menusidebar'
                        />
                    )}
                </p>
                <Collapse isOpen={(props.sidebarAtivo || !props.modoMobile)} navbar>
                    <Nav className="flex-column">
                        {
                            props.sidebarAtivo ?
                                <div className="flex-column nome-usuario-submenu">
                                    <h5>{localStorage.getItem('nome').charAt(0).toUpperCase() + localStorage.getItem('nome').slice(1)}</h5>
                                </div> : ""
                        }
                        {props.funcionalidades.map(elemento => (
                            <SidebarItem
                                {...elemento}
                                onClick={props.ativarSidebar}
                                sidebarAtivo={props.sidebarAtivo}
                                key={elemento.descricao}
                            />
                        ))}
                        <SidebarItem
                            url={"/sair"}
                            onClick={props.ativarSidebar}
                            logout={props.logout}
                            sidebarAtivo={props.sidebarAtivo}
                            descricao={"Sair"}
                        />
                    </Nav>
                </Collapse>
            </Scrollbars>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actionsRedux.logout()),
        ativarSidebar: (evento) => dispatch(actionsRedux.ativarSidebar(evento)),
    }
};

const mapStateToProps = (state) => ({
    modoMobile: state.core.modoMobile,
    sidebarAtivo: state.core.sidebarAtivo,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

