import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { axiosApi } from '../Basicos/axiosInstances';
import { Row, Container, Card, CardBody, Col } from 'reactstrap';
import { MenuDasFuncionalidades } from '../Menu';
import CardNoticiaMaisLida from '../Conteudo/Cards/CardNoticiaMaisLida';
import { NewLoader } from '../Basicos';
import { parseISO } from 'date-fns/esm/fp';
import { pt_br } from '../Basicos/funcoes';
import { primeiraImagemDeUmaNoticia } from '../../services/noticias';

function Noticia(params) {
    let { id } = useParams();

    const [noticia, setNoticia] = useState();
    const [maisLidas, setMaisLidas] = useState();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);

        axiosApi
            .get(`/eventos-noticias/${id}`)
            .then(resp => {
                const { noticia, maisLidas } = resp.data;
                setNoticia(noticia);
                setMaisLidas(maisLidas);
            })
            .finally(() => {
                setLoader(false);
            });

    }, [id]);

    const formataDataNoticia = (data) => {
        let mes = pt_br.months[data.getMonth()];
        let horas = data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
        let minutos = data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes();

        return `${data.getDate()} de ${mes} de ${data.getFullYear()} - ${horas}:${minutos}`;
    }
    const texto = noticia?.texto;

    const regex = /https?:\/\/\S+/g;

    const textoFormatado = texto?.replace(regex, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
    });

    return (
        <Row noGutters={true} xs="1">
            <MenuDasFuncionalidades
                classNavBar="navbar-consultas"
                titulo="Notícias e Eventos"
                funcionalidade="noticia-individual"
            />
            <Container className="mt-5">
                {
                    noticia && maisLidas
                        ? <div className="px-5"> <Row xs="1" md="2">
                            <Col md="8">
                                <Card className='card-noticias'>
                                    <NewLoader isActive={loader}>
                                        <CardBody>
                                            <div>
                                                <div>
                                                    <h1 className="font-weight-bold noticia__titulo mb-3">
                                                        {noticia.titulo}
                                                    </h1>
                                                </div>
                                                <div>
                                                    <span className="noticia__dataHora mb-5">
                                                        {formataDataNoticia(parseISO(noticia.created_at))}
                                                    </span>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-center py-3'>
                                                    <img
                                                        src={primeiraImagemDeUmaNoticia(noticia)}
                                                        className="shadow-sm mb-3"
                                                        alt=""
                                                        style={{ width: "100%", height: "400px", objectFit: 'cover', borderRadius: '16px' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 formatted-text" dangerouslySetInnerHTML={{ __html: textoFormatado }} />
                                            {
                                                noticia.exibir_video
                                                    ? <iframe
                                                        frameBorder="0"
                                                        allowFullScreen
                                                        title="Video"
                                                        className="noticia__video"
                                                        width="100%"
                                                        height="527"
                                                        src={noticia.url_video}
                                                    />
                                                    : null
                                            }
                                        </CardBody>
                                    </NewLoader>
                                </Card>
                            </Col>
                            <Col md="4">
                                <CardNoticiaMaisLida
                                    noticias={maisLidas}
                                    noticiaAtual={noticia}
                                />
                            </Col>
                        </Row></div>
                        : null
                }
            </Container>
        </Row>
    );
}

export default Noticia;
