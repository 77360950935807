import React, { useState, useEffect, Fragment } from 'react'
import { Card, Row, Col, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import { NewLoader, TableWithVirtualizedRow } from '../Basicos';
import * as actionsRedux from './../Store/Actions/index';
import ModalAgruparAlunos from '../Basicos/Modal/ModalAgruparAlunos';
import ModalPeriodoCurso from '../Modal/ModalPeriodoCurso';

function AssociadorDeGrupos({
    ativar,
    curso,
    setCursoId,
    periodoLetivo,
    setPeriodoLetivoId,
    subFuncionalidade: {
        url
    }
}) {
    const [semestres, setSemestres] = useState([]);

    const [semestreId, setSemestreId] = useState();

    const [semetreDescricao, setSemetreDescricao] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [periodosLetivos, setPeriodosLetivos] = useState([]);

    const [cursos, setCursos] = useState([]);

    const [GruposAssociados, setGruposAssociados] = useState();

    const [alunos, setAlunos] = useState();

    const [alunosIds, setAlunosIds] = useState([])

    const [grupos, setGrupos] = useState();

    const [grupos2, setGrupos2] = useState([]);

    //modais
    const [modal, setModal] = useState(false);
    const [modalAgrupar, setModalAgrupar] = useState(false); // Remover Se Nao Quebrar
    const [modalAdicao, setModalAdicao] = useState(false);

    const semestresDoCursoSelecionado = () => semestres.filter(semestre => semestre.curso_id === curso);

    const cursoSelecionado = () => cursos.find(c => c.id === curso);

    const periodoLetivoSelecionado = () => periodosLetivos.find(pL => pL.id === periodoLetivo);
    const toggleModal = () => setModal(!modal);

    const gerar = (semestre_id) => {
        setIsLoading(true);
        axiosApi({
            method: 'get',
            url,
            params: {
                semestre_id,
                periodo_letivo_id: periodoLetivo,
            }
        }).then(resp => {
            setAlunos(resp.data.alunos);
            setSemetreDescricao(resp.data.semestre)
            setGrupos(resp.data.grupo)
            setGrupos2(resp.data.grupo2)
            setModalAdicao(true)
        }).catch(error => {
            if (error.response && error.response.data.erro)
                ativar(error.response.status, error.response.data.erro);
        }).finally(() => {
            setIsLoading(false);
        });
    }
    //Requisição inicial 
    useEffect(() => {
        axiosApi.get(url).then(resp => {
            setSemestres(resp.data.semestre);
            setPeriodosLetivos(resp.data.periodo);
            setGruposAssociados(resp.data.GruposAssociados);
            setCursos(resp.data.cursos);
            setAlunos(resp.data.alunos);
        }).catch(error => {
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }
        });
    }, [url]);

    const SemestreItem = ({ semestre }) => {
        return (
            <Col className="mt-5">
                <Card className="d-flex py-5 ofertaPeriodo__card">
                    <span className="font-weight-bold azul-principal mx-auto mb-3 ofertaPeriodo__card--titulo">
                        {semestre.descricao}
                    </span>
                    <Button
                        className="font-weight-bold mx-auto"
                        onClick={() => gerar(semestre.id, alunos)}
                    >
                        Visualizar
                    </Button>
                </Card>
            </Col>
        );
    }

    const CardComSemestres = () => {
        return (
            <Card className="mt-3 px-5 pb-5">
                <Row xs="1" sm="2" md="3" lg="4">
                    {
                        semestresDoCursoSelecionado().map(semestre => <SemestreItem semestre={semestre} key={semestre.id} />)
                    }
                </Row>
            </Card>
        );
    }

    const Titulo = () => {
        return (
            <h1 className="m-0 font-weight-bold label-nome-pagina">
                {periodoLetivoSelecionado()?.descricao} - {cursoSelecionado()?.nome}
                <Button onClick={toggleModal} className="float-right">
                    Editar
                </Button>
            </h1>
        );
    }
    return (
        <NewLoader isActive={isLoading} overlay >
            {
                (periodosLetivos && cursos && GruposAssociados && periodoLetivo && curso) &&
                <Fragment>
                    <Titulo />
                    <CardComSemestres />
                </Fragment>

            }
            {
                (periodosLetivos && cursos) &&
                <ModalPeriodoCurso
                    isOpen={modal}
                    setIsOpen={setModal}
                    cursos={cursos}
                    periodosLetivos={periodosLetivos}
                    cursoId={curso}
                    setCursoId={setCursoId}
                    periodoLetivoId={periodoLetivo}
                    setPeriodoLetivoId={setPeriodoLetivoId}
                />

            }
            {
                <ModalAdição
                    ativar={ativar}
                    grupos={grupos}
                    grupos2={grupos2}
                    alunos={alunos}
                    setAlunos={setAlunos}
                    modalAdicao={modalAdicao}
                    setModalAdicao={setModalAdicao}
                    semestre={semetreDescricao}
                    alunosIds={alunosIds}
                    setAlunosIds={setAlunosIds}
                    modalAgrupar={modalAgrupar}
                    setModalAgrupar={setModalAgrupar}
                    cursoId={curso}
                    semestreId={semestreId}
                    setSemestreId={setSemestreId}
                    periodoLetivo={periodoLetivo}
                />
            }
        </NewLoader>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        adicionarOnClick: (objeto) => dispatch(actionsRedux.adicionarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade, objeto)),
        setCursoId: (tipo, acao) => dispatch(actionsRedux.setCurso(tipo, acao)),
        setPeriodoLetivoId: (tipo, acao) => dispatch(actionsRedux.setPeriodoLetivo(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        curso: state.auth.cursoId,
        periodoLetivo: state.auth.periodoLetivoId
    }
}

function ModalAdição({
    ativar,
    alunos,
    grupos,
    grupos2,
    semestre,
    setAlunos,
    alunosIds,
    setAlunosIds,
    modalAdicao,
    setModalAdicao,
    modalAgrupar,
    setModalAgrupar,
    cursoId,
    semestreId,
    setSemestreId,
    periodoLetivo
}) {
    const gruposOptions = () => grupos.map(grupos => ({ value: grupos.id, label: grupos.descricao }));

    const grupos2Options = () => grupos2.map(grupos2 => ({ value: grupos2.id, label: grupos2.descricao }));

    const updateGrupoAlunos = async (objeto) => {
        const alunosPrev = alunos.slice()
        const alunoIndex = alunosPrev.findIndex(aluno => aluno.id == objeto.row_id)
        try {
            var novoObj = { ...objeto, periodo_letivo_id: periodoLetivo }
            const response = await axiosApi.put(`alunos/grupos-de-alunos/${objeto.row_id}`, novoObj);
            if (response.status === 201) {
                alunosPrev[alunoIndex] = {
                    ...alunosPrev[alunoIndex],
                    [objeto.campo]: objeto.valor
                }
                setAlunos(alunosPrev)
            }
        } catch (error) {
            ativar(400, 'Grupo Não pode se Repetir')
            console.log(error)
        }
    }

    const regex = /^([A-Za-z]{1})(\d{1,2})|(^$)$/

    const getAlunosIdFromTable = (acao, objeto) => {
        const { id, semestre_id } = objeto
        if (acao === 'add') {
            setAlunosIds([...alunosIds, id])
            setSemestreId(semestre_id)
        }
        if (acao === 'remove') {
            const prevAlunosIds = alunosIds.filter(alunoId => alunoId !== id)
            setAlunosIds(prevAlunosIds)
        }
    }

    const criarGruposAlunos = async (objeto) => {
        try {
            const response = await axiosApi.post(`alunos/grupos-de-alunos/`, objeto)
            ativar(response.status, response.data.sucesso)
        } catch (error) {
            console.log(error)
            ativar(400, 'Já Existe aluno com o grupo referido')
            console.log(error)
        }
    }
    return (
        <Modal isOpen={modalAdicao} size="lg" id="modal-lista-alunos-grupo">
            <ModalHeader className="modal-content">
                <p className="m-0 font-weight-bold nome_modal" a> S{semestre.descricao} -  {semestre?.cursos?.abreviatura}</p>
            </ModalHeader >
            <ModalBody>
                <TableWithVirtualizedRow
                    tabelaClassName="tabelaConsulta"
                    updateMyData={updateGrupoAlunos}
                    getAlunosIdFromTable={getAlunosIdFromTable}
                    alunosIds={alunosIds}
                    columns={[
                        {
                            Header: '#',
                            id: '#',
                            style: { width: '50px' },
                            inputType: 'checkbox'
                        },
                        {
                            Header: 'Cód. Vertical',
                            accessor: 'cod_vertical',
                            disabled: true,
                        },
                        {
                            Header: 'Cód. Horizontal',
                            accessor: 'cod_horizontal',
                            disabled: true,
                        },
                        {
                            Header: 'Matrícula',
                            accessor: 'matricula',
                            disabled: true,
                        },
                        {
                            Header: 'Nome',
                            accessor: 'nome',
                            id: 'id',
                            disabled: true,
                            style: { width: '250px' },

                        },
                        {
                            Header: 'Grupo',
                            accessor: 'grupo',
                            id: 'grupo',
                            style: { width: '150px' },
                            regex: regex,
                        },
                        {
                            Header: 'Tutoria',
                            accessor: 'tutoria_id',
                            id: 'tutoria_id',
                            inputType: 'select',
                            selectOptions: grupos2 && grupos2Options(),
                            selectOptionsDisponivel: grupos2 && grupos2Options(),
                            style: { width: '150px' },
                        },
                    ]}
                    data={alunos}
                />
                <Button onClick={() => (setModalAdicao(false), setAlunosIds([]))} className="float-left btn-transparente mt-2">Fechar</Button>
                <Button onClick={() => setModalAgrupar(true)} color="primary" className="float-right mt-2">Agrupar</Button>
            </ModalBody>
            <ModalAgruparAlunos
                isOpen={modalAgrupar}
                setIsOpen={setModalAgrupar}
                alunosIds={alunosIds}
                setAlunosIds={setAlunosIds}
                selectOptions={grupos2Options()}
                setModalAdicao={setModalAdicao}
                criarGruposAlunos={criarGruposAlunos}
                cursoId={cursoId}
                semestreId={semestreId}
            />
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssociadorDeGrupos)