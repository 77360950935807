import * as tiposActions from '../Actions/tiposActions'
import { setStateRedux } from '../setStateRedux'

const initialState = {
    windowHeight: undefined,
    windowWidth: undefined,
    modoMobile: undefined,
    sidebarAtivo: false,
    solucao_id: undefined,
    funcionalidades: undefined
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case tiposActions.ATUALIZAR_TAMANHO_TELA:
            return setStateRedux(state, { modoMobile: action.modoMobile, windowHeight: action.windowHeight, windowWidth: action.windowWidth })
        case tiposActions.INICIALIZAR_CORE:
            return setStateRedux(state, { funcionalidades: action.funcionalidades });
        case tiposActions.ALTERAR_SOLUCAO:
            return setStateRedux(state, { solucao_id: parseInt(action.solucao_id) });
        case tiposActions.ALTERAR_ESTADO_SIDEBAR:
            return setStateRedux(state, { sidebarAtivo: action.sidebarAtivo });
        default:
            return state;
    }
}

export default reducer