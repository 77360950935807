import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FaTimes, FaPlus, FaExternalLinkAlt } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import CampoGenerico from "../Campos/campoGenerico"

const HeaderModal = ({ sm, md, toggle, campos, dados, titulo, colClassName, colStyle, salvarOnClick, createOnClick, definirOnclick, icon = true }) => {
    return (
        <Fragment>
            <Col sm={sm} md={md} className={colClassName} style={colStyle}>
                <div
                    className="titulo-header-modal"
                    style={{ width: createOnClick || salvarOnClick ? "calc(100% - 250px)" : "calc(100% - 180px)" }}
                >
                    {titulo}
                </div>
                {icon == true ? <FaTimes color="white" size="1.5em" onClick={toggle} style={iconStyle} /> : ''}
                {createOnClick && (
                    <FaPlus
                        color="white"
                        size="1.4em"
                        onClick={() => definirOnclick({ onClick: createOnClick })}
                        style={iconStyle}
                    />
                )}
                {salvarOnClick && (
                    <div
                        style={divStyle}
                        onClick={() => definirOnclick({ onClick: salvarOnClick })}
                    >
                        <sup style={supStyle}>Gerar</sup>
                        <FaExternalLinkAlt color="white" size="1.4em" style={svgStyle} />
                    </div>
                )}
                <CamposGenericos dados={dados} campos={campos} />
            </Col>

        </Fragment>
    )
}

const iconStyle = {
    cursor: 'pointer',
    float: 'right'
};

const divStyle = {
    cursor: 'pointer',
    float: 'right',
    width: '100px',
    margin: '0px 5px',
    backgroundColor: '#FFFFFF',
    padding: '1px 10px',
    borderRadius: '20px',
};

const supStyle = {
    fontSize: '20px',
    display: 'contents',
    float: 'left',
    color: 'var(--cor-text-verde)',
};

const svgStyle = {
    color: 'var(--cor-text-verde)',
    float: 'right',
};

HeaderModal.propTypes = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

HeaderModal.defaultProps = {
    createOnClick: undefined,
    xs: undefined,
    sm: 12,
    md: 12,
    lg: undefined,
    xl: undefined,
    colClassName: "menu-marcacoes-header",
    colStyle: { padding: '12px 12px 2px 12px' },
};

export default HeaderModal;

function CamposGenericos({ dados, campos }) {
    if (!dados)
        return null;

    return (
        <Col sm={12} className='header-modal-secundario'>
            <Row noGutters>
                {
                    campos.map((elemento, indice) =>
                        <CampoGenerico {...elemento} dados={dados} key={indice} />
                    )
                }
            </Row>
        </Col>
    );
}
