import React, { Fragment, useEffect } from 'react'
import { Col, Form, FormGroup, ModalBody, Button, Modal } from 'reactstrap'
import { SelectForm } from '../Basicos'

const ModalCursoPeriodo = ({ isOpen, setIsOpen, selectPeriodo, selectCursos, dados, setDados, getSubgruposCurso }) => {

    const selectOnChange = (name, value) => {
        setDados({
            ...dados,
            [name]: value
        })
    }

    const submit = () => {
        if (dados.curso_id !== undefined) {
            getSubgruposCurso(dados.curso_id, dados.periodo_letivo_id)
            setIsOpen(false)
        }
    }
    return (selectPeriodo && selectCursos && (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <Form>
                    <FormGroup row>
                        <Col sm={10}>
                            <SelectForm
                                name="curso_id"
                                label="Curso"
                                // value={cursosValue()}
                                options={selectCursos}
                                onChange={selectOnChange}

                            />
                            <SelectForm
                                name="periodo_letivo_id"
                                label="Período Letivo"
                                // value={periodosLetivosValue()}
                                options={selectPeriodo}
                                onChange={selectOnChange}
                            />
                        </Col>
                    </FormGroup>
                    <Button onClick={() => setIsOpen(false)} className="float-left btn-transparente">Cancelar</Button>
                    <Button onClick={() => submit()} color="primary" className="float-right">Procurar</Button>
                </Form>
            </ModalBody>
        </Modal>)
    )
}

export default ModalCursoPeriodo