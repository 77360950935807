import React, { useState, useEffect, Fragment } from 'react'
import { Card, Row, Col, Button, Modal, ModalBody, Form, FormGroup } from "reactstrap";
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import { SelectForm, NewLoader } from '../Basicos';
import * as actionsRedux from '../Store/Actions/index';
import { Link } from 'react-router-dom';

function RodiizioExterna({
    ativar,
    ativarCofirmacao,
    subFuncionalidade: {
        url
    }
}){
    const [semestres, setSemestres] = useState([]);
    
    const [isLoading, setIsLoading] = useState(false);

    const [periodosLetivos, setPeriodosLetivos] = useState([]);

    const [periodoLetivo, setPeriodoLetivo] = useState();

    const [cursos, setCursos] = useState([]);

    const [curso, setCurso] = useState();

    const [RodiziossAssociados, setRodiziossAssociados] = useState();
    
    const [modal, setModal] = useState(false);

    const periodosLetivosOptions = () => 
        periodosLetivos.map(periodoLetivo => ({ value: periodoLetivo.id, label: periodoLetivo.descricao }));

    const periodosLetivosValue = () => periodosLetivosOptions().find(elemento => elemento.value === periodoLetivo);

    const cursosOptions = () => cursos.map(curso => ({ value: curso.id, label: curso.nome }));

    const cursosValue = () => cursosOptions().find(elemento => elemento.value === curso);

    const semestresDoCursoSelecionado = () => semestres.filter(semestre => semestre.curso_id === curso);

    const cursoSelecionado = () => cursos.find(c => c.id === curso);

    const periodoLetivoSelecionado = () => periodosLetivos.find(pL => pL.id === periodoLetivo); 

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if(periodoLetivo)
            setModal(false);
    }

    const gera = (semestre_id) => { 
        setIsLoading(true);
        // console.log(url);
        axiosApi({ 
            // setModal : (true),
            method: 'post',
            url,
            data: {
                semestre_id ,
                periodo_letivo_id: periodoLetivo, 
            } 
        }).then(resp => {
            if(RodiziossAssociados[periodoLetivo] !== undefined)
                setRodiziossAssociados({
                    ...RodiziossAssociados, 
                    [periodoLetivo]: [...RodiziossAssociados[periodoLetivo], semestre_id]
                });
            else
                setRodiziossAssociados({
                    ...RodiziossAssociados, 
                    [periodoLetivo]: [semestre_id]
                });
                
            ativar(201, resp.data.sucesso);
        }).catch(error => {
            if(error.response && error.response.data.erro)
                ativar(error.response.status, error.response.data.erro);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    //Requisição inicial 
    useEffect(() => {
        axiosApi.get(url).then(resp => {
            setSemestres(resp.data.semestre);
            setPeriodosLetivos(resp.data.periodo);
            setRodiziossAssociados(resp.data.RodiziossAssociados);
            setCursos(resp.data.cursos);
            setModal(true);
        });
    }, [url]);

    //Ao gerar, modal confirmação
    const BoataoAgrupar = ({ semestre }) => 
        <Button 
            className="font-weight-bold mx-auto"    
            onClick={() => ativarCofirmacao({titulo: 'alocar', mensagem: 'Alocar atividades ao semestre ?', callback: () => gera(semestre.id)})}
        >Alocar</Button>

    const BotaoVisualizar = ({url, semestre}) => {
        return (
            <Link 
                className="mx-auto"
                to={`${url}/semestre/${semestre.id}/periodo-letivo/${periodoLetivo}`} 
            >
                <Button className="font-weight-bold btn--invertido">Visualizar</Button> 
            </Link>
        );
    }

    const SemestreItem = ({semestre}) => {
        return (
            <Col className="mt-5">
                <Card className="d-flex py-5 ofertaPeriodo__card">
                    <span className="font-weight-bold azul-principal mx-auto mb-3 ofertaPeriodo__card--titulo">
                        {semestre.descricao}
                    </span>
                    {
                        RodiziossAssociados[periodoLetivo]?.includes(semestre.id) 
                            ?   <BotaoVisualizar url={url} semestre={semestre} />   
                            :   <BoataoAgrupar semestre={semestre} />  
                    }
                </Card>
            </Col>
        );
    }

    const CardComSemestres = () => {
        return (
            <Card className="mt-3 px-5 pb-5">
                <Row xs="1" sm="2" md="3" lg="4">
                    {
                        semestresDoCursoSelecionado().map(semestre =>
                             <SemestreItem semestre={semestre} key={semestre.id} />
                        )
                    }
                </Row>
            </Card>
        );
    }

    const Titulo = () => {
        return (
            <h1 className="m-0 font-weight-bold label-nome-pagina">
                {periodoLetivoSelecionado().descricao} - {cursoSelecionado().nome}
            </h1>
        );
    }

    return(
        <NewLoader isActive={isLoading} overlay >
            {
                RodiziossAssociados && periodoLetivo && curso 
                ?   <Fragment>
                        <Titulo />
                        <CardComSemestres />
                    </Fragment>
                :   null
            }
            {
                periodosLetivos && cursos
                ?   <Modal isOpen={modal}>
                        <ModalBody>
                            <Form onSubmit={handleFormSubmit}>
                                <FormGroup row>
                                    <Col sm={10}>
                                        {
                                            <Fragment>
                                                <SelectForm
                                                    name="periodoLetivoSelect"
                                                    label="Período Letivo"
                                                    value={periodosLetivosValue()}
                                                    options={periodosLetivosOptions()}
                                                    onChange={(input, value) => setPeriodoLetivo(value)}
                                                />
                                                <SelectForm
                                                    name="cursoSelect"
                                                    label="Curso"
                                                    value={cursosValue()}
                                                    options={cursosOptions()}
                                                    onChange={(input, value) => setCurso(value)}
                                                />
                                            </Fragment>
                                        }
                                    </Col>
                                </FormGroup>
                                <Button color="primary" className="float-right"> Procurar </Button>
                            </Form>
                        </ModalBody>
                    </Modal>
                :   null
            }
        </NewLoader>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RodiizioExterna)