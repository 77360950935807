import React, { Component } from 'react'
import { Input, FormGroup, FormFeedback, Label } from "reactstrap"
// import { FiEye, FiEyeOff } from "react-icons/fi";

//mport { selectStyle } from './funcoes'
//import Select from 'react-select'

export default class IconInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pattern: false,
            tipoPattern: this.props.tipoPattern,
            mensagem: "",
            manipuladoPeloUsuario: false,
            // isRevealPassword: false

        }
    }
    onChange2 = (event) => {
        const { name, value } = event.target.value;
        this.setState({ [name]: value });
    }


    // togglePassword = event => {
    //     this.setState({
    //         isRevealPassword: !this.state.isRevealPassword
    //     });
    //     return(
    //         <span onClick={this.togglePassword} ref={this.iconRef}>

    //         {event.isRevealPassword ?
    //             <FiEye  className="customIcon" /> :
    //             <FiEyeOff  className="customIcon" />
    //         }
    // </span>   

    //     )
    // }




    onChange = (valor) => {
        this.props.onChange(this.props.name, valor)
    }

    isValid = () => {
        return ((this.state.manipuladoPeloUsuario || this.props.submit) && (this.props.invalido || this.state.pattern))
    }

    changeClass = () => {
        return this.isValid() ? "input-invalid is-invalid" : "";
    }

    validar = (event) => {
        if (this.state.tipoPattern) {
            this.props.onChange(event.target.name, event.target.value)
        } else {

            var reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            var resultado = reg.test(event.target.value);
            if (resultado)
                this.props.onChange(event.target.name, "")
            else
                this.props.onChange(event.target.name, event.target.value)

            this.setState({ pattern: !resultado, mensagem: "Campo não está no padrão.." })
        }
    }

    mensagemSenha = () => {
        if (this.props.name === 'senha_confirmation') {
            return 'Senha não confere com a primeira '
        } else if (this.state.pattern) {
            return this.state.mensagem
        } else {
            return "Digite uma opção valida !"
        }
    }

    tipoInput = () => {

    }

    render() {
        // const { isRevealPassword } = this.state;

        switch (this.props.tipo) {
            case 'usuario':
                return (
                    <FormGroup>
                        <Label>Email do usuário</Label>
                        <Input type={"text"}
                            placeholder={this.props.placeholder}
                            defaultValue={this.props.defaultValue}
                            name={"usuario"}
                            onChange={this.validar}
                            onBlur={() => this.setState({ manipuladoPeloUsuario: true })}
                            id={"usuario"}
                            className={this.changeClass()}
                        />
                        <FormFeedback >
                            {this.state.pattern ? this.state.mensagem : "Digite uma opção valida !"}
                        </FormFeedback>
                    </FormGroup>)
            case 'senha':
                return (
                    <FormGroup>
                        <Label>Senha</Label>
                        <Input type={"password"}
                            placeholder={this.props.placeholder}
                            name={this.props.name}
                            onChange={(event) => this.onChange(event.target.value)}
                            onBlur={() => this.setState({ manipuladoPeloUsuario: true })}
                            className={this.changeClass()}

                        />
                        <FormFeedback>
                            {this.mensagemSenha()}
                        </FormFeedback>
                    </FormGroup>)
            case 'senha_confirmacao':
                return (
                    <FormGroup>
                        <Label>Confirmar Senha</Label>
                        <Input type={"password"}
                            placeholder={this.props.placeholder}
                            name={this.props.name}
                            onChange={(event) => this.onChange(event.target.value)}
                            onBlur={() => this.setState({ manipuladoPeloUsuario: true })}
                            className={this.changeClass()}

                        />
                        <FormFeedback>
                            {this.mensagemSenha()}
                        </FormFeedback>
                    </FormGroup>)
            case 'senhaVisivelConfirmacao':
                return (
                    <FormGroup>
                        <Label>Confirmar Senha</Label>
                        <Input type={"text"}
                            placeholder={this.props.placeholder}
                            name={this.props.name}
                            onChange={(event) => this.onChange(event.target.value)}
                            onBlur={() => this.setState({ manipuladoPeloUsuario: true })}
                            className={this.changeClass()}

                        />
                        <FormFeedback>
                            {this.mensagemSenha()}
                        </FormFeedback>
                    </FormGroup>)
            case 'senhaVisivel':
                return (
                    <FormGroup>
                        <Label>Senha</Label>
                        <Input type={"text"}
                            placeholder={this.props.placeholder}
                            name={this.props.name}
                            onChange={(event) => this.onChange(event.target.value)}
                            onBlur={() => this.setState({ manipuladoPeloUsuario: true })}
                            className={this.changeClass()}

                        />
                        <FormFeedback>
                            {this.mensagemSenha()}
                        </FormFeedback>
                    </FormGroup>)
            default:
                return null

        }
    }
}
