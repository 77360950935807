import React from 'react'
import { Table } from 'reactstrap'
const LinhaTabela = ({ row, horizontal, vertical }) => {
    const { modulo, carga_horaria_alocada } = row
    return (
        <tr>
            <td>{modulo}</td>
            <td>{modulo === 'Horizontal' ? horizontal : vertical}</td>
            <td>{carga_horaria_alocada}</td>
            <td>{(modulo === 'Horizontal' ? horizontal : vertical) - carga_horaria_alocada}</td>
        </tr>
    )
}

const TabelaCargaHorariaModulo = ({ chModulos }) => {

    const total = {
        horizontal: chModulos && chModulos.find(dado => (dado.horizontal > 0)),
        vertical: chModulos && chModulos.find(dado => (dado.vertical > 0)),
        alocado: chModulos && chModulos[0].carga_horaria_alocada + chModulos[1].carga_horaria_alocada
    }

    return (
        <Table hover>
            <thead style={{ background: 'var(--cor-principal)', color: 'white', textAlign: 'center' }}>
                <tr>
                    <th colSpan="4">
                        Distribuição de carga horária por módulo
                    </th>
                </tr>
                <tr>
                    <th>Módulo</th>
                    <th>Previsão carga horária</th>
                    <th>Alocação carga horária</th>
                    <th>Diferença</th>
                </tr>
            </thead>
            <tbody style={{ textAlign: 'center' }}>
                {chModulos && chModulos.map((row, index) => (
                    <LinhaTabela
                        key={index}
                        row={row}
                        horizontal={total.horizontal?.horizontal ?? 0}
                        vertical={total.vertical?.vertical ?? 0}
                    />
                ))}
                <tr style={{background: '#f2f2f2'}}>
                    <td><strong>Total</strong></td>
                    <td><strong>{(total.horizontal?.horizontal ?? 0) + (total.vertical?.vertical ?? 0)}</strong></td>
                    <td><strong>{total.alocado}</strong></td>
                    <td><strong>{((total.horizontal?.horizontal ?? 0) + (total.vertical?.vertical ?? 0)) - total.alocado}</strong></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default TabelaCargaHorariaModulo