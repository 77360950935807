import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Navbar } from 'reactstrap';
import { FiltroConsulta } from '../Basicos';

const MenuHoc = (ComponentWrap) => {
    const HOCcomponent = (props) => {
        return (
            <React.Fragment>
                <Col sm={12} md={12} className="border-temp">
                    <Navbar color="white" light expand="md" className={props.classNavBar}>
                        <Row noGutters={true}>
                            <ComponentWrap {...props} />
                        </Row>
                    </Navbar>
                </Col>
                {(props.filtro && props.estruturaFiltro) && (
                    <FiltroConsulta 
                        gerarConsulta={props.gerarConsulta}
                        estrutura={props.estruturaFiltro}
                        params={props.paramsConsulta} 
                    />
                )}
            </React.Fragment>
        )
    }
    HOCcomponent.propTypes = {
        classNavBar: PropTypes.string,
        titulo: PropTypes.string.isRequired,
        inputChange: PropTypes.func,
        radioChange: PropTypes.func,
        gerarConsulta: PropTypes.func,
        filtro: PropTypes.bool,
        estruturaFiltro: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        paramsConsulta: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    }
    return HOCcomponent
}

export default MenuHoc;
