import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from 'react-dom';
import { Input } from 'reactstrap';
import { useTable, useGlobalFilter, usePagination } from "react-table";

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
    pesquisarGlobal = false
}) {
    const [mounted, setMount] = useState(false)

    useEffect(() => {
        setMount(true);
    }, []);

    if (pesquisarGlobal && mounted) {
        const dom = document.getElementById('pesquisar-wrap');
        return ReactDOM.createPortal(
            <Input
                type={"text"}
                name={"pesquisa"}
                placeholder={"Pesquisar"}
                value={globalFilter || ''}
                onChange={e => { setGlobalFilter(e.target.value || undefined) }}
            />
            , dom);
    } 
    return null;
}

function  TabelaModal({ 
    columns, 
    data, 
    pesquisarGlobal,
    tabelaClassName = "table-default"
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
       
        state: {  globalFilter },
        // preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    return (
        <div style={{width: '100%', padding:"0 1rem"}}>
            <GlobalFilter
                // preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                pesquisarGlobal={pesquisarGlobal}
            />
            {data && data.length > 0 ? (
                <Fragment>
                    <table {...getTableProps()} className={tabelaClassName}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps({
                                            className: `${column.className} ${tabelaClassName}__celulaCabecalho`,
                                            style: column.style,
                                          })}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell=> {
                                            return (

                                                <td {
                                                    ...cell.getCellProps({
                                                        className: `${cell.column.className} ${tabelaClassName}__celulaDados`,
                                                        style: cell.column.style,
                                                        default: "N/A"
                                                    })
                                                }>

                                                  {
                                            //    console.log = row,
                                               cell.value || cell.column.id === "acoes" || cell.column.id === "Cor" || cell.column.id === "Grupo 1" || cell.column.id === "Grupo 2" || cell.column.id === "Professor" 
                                                        ? cell.render('Cell')
                                                        : "N/A"                                                    
                                                  }
                                                  
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Fragment>
            ) : (
                <p className="sem-dados">Não há dados disponíveis</p>
            )}
        </div>
    );
}

export default TabelaModal;


