import React from 'react'
import { ModalConsulta } from '.';
import { Tabela } from '../Basicos';

function ModalAulasCanceladas(props) {
    const { estrutura } = props;
    const { alunos } = estrutura?.modal;

    const columns = React.useMemo(
        () => [{
            Header: "alunos",
            style: {
                display: 'none',
            },
            columns: [
                {
                    Header: 'Aluno',
                    accessor: 'matricula'
                },
                {
                    Header: 'Nome',
                    accessor: 'nome_aluno',
                },
                {
                    Header: 'Grupo',
                    accessor: 'grupo_individual',
                },
                {
                    Header: 'Atividade',
                    accessor: 'nome_atividade',
                },
                {
                    Header: 'CPF',
                    accessor: 'cpf_aluno',
                },
                {
                    Header: 'Local - UAPS, Hospital e etc',
                    accessor: 'local',
                },
                {
                    Header: 'Dias',
                    accessor: 'atividade_data_chr',
                    type: "arrayData"
                },

            ],
        }],
        []
    )

    return (
        <ModalConsulta
            data={props.data}
            modalClass="modal-map"
            modalBodyClass="modalConsulta"
            size="lg"

            {...props}
        >
            <Tabela
                columns={columns}
                data={alunos}
                tabelaClassName="tabelaConsulta"
            />
        </ModalConsulta>
    );
}

export default ModalAulasCanceladas;
