import React, { Fragment } from 'react';

const NewLoader = ({ fixed, overlay, padding, children, isActive }) => {
    if (overlay) {
        return (
            <Fragment>
                {isActive && (
                    <div className="overlay" style={{ position: fixed ? 'fixed' : 'absolute' }}>
                        <div className="loader" style={{ margin: padding ? '8px auto' : 'auto' }} />
                    </div>
                )}
                {children}
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                {isActive && (<div className="loader" style={{ margin: padding ? '8px auto' : 'auto' }} />)}
                {!isActive && (children)}
            </Fragment>
        );
    }
};

export default NewLoader;
