import React from 'react'
import {Col, Row} from 'reactstrap'
const ErroAcesso = () => {
  return (
    <Row noGutters = {true} className = 'h-100'>
      <Col sm = {12} className = 'texto-secundario div-text-middle'>
          <h1>Ooops, algum erro aconteceu....</h1>
          <p>Provalvemente você não tem acesso as funcionalidade</p>
          <p>Contate seu administrador !</p>
      </Col>
    </Row>
  )
}

export default ErroAcesso
